import React from 'react';
import styles from './KnowledgeCenter.module.css';
import Safetyimg from "../../assets/Safety-img.png";
import tick from "../../assets/tick.png";
import MythReality from "./MythReality"
import CompoundingPharmacies from "./CompoundingPharmacies"
import FAQ from "./FAQ"

function Safety() {
    document.querySelectorAll('.safetyLink').forEach(link => {
        link.addEventListener('click', function(e) {
            e.preventDefault();
            const target = document.querySelector(this.getAttribute('href'));
            window.scrollTo({
                top: target.offsetTop - 175,
                behavior: 'smooth'
            });
        });
    });

    return (
        <>
            <div className={styles.safetyTitle}>
                <img src={Safetyimg} alt='' />Safety is our top priority
            </div>
            <div className={styles.safetySection}>
               <div className={styles.safetyItem}>
                    <img src={tick} alt='' />
                    <div>We believe a big part of safety is education.</div>
                </div>
                <div className={styles.safetyItem}>
                    <img src={tick} alt='' />
                    <div>We want you to understand the risks and benefits of the medications we prescribe.</div>
                </div>
                <div className={styles.safetyItem}>
                    <img src={tick} alt='' />
                    <div>We want you to understand what to expect when you take them.</div>
                </div>
                <div className={styles.safetyItem}>
                    <img src={tick} alt='' />
                    <div>We want you to know what to do if you experience side effects.</div>
                </div>
                <div className={styles.safetyItem}>
                    <img src={tick} alt='' />
                    <div>We want you to know what to do if you have questions.</div>
                </div>
                <div className={styles.safetyItem}>
                    <img src={tick} alt='' />
                    <div>We want you to know what to do if you need help.</div>
                </div>
                <div className={styles.safetyItem}>
                    <img src={tick} alt='' />
                    <div>Please explore the resources below to learn more.</div>
                </div>
            </div>
            <div className={styles.safetyButtons}>
                <a href="#MythsvsReality" className={styles.safetyLink}>Myths vs. Reality</a>
                <a href="#GLP1svsObesity" className={styles.safetyLink}>GLP-1s vs. Obesity</a>
                <a href="#CompoundingPharmacies" className={styles.safetyLink}>Compounding Pharmacies</a>
                <a href="#FAQ" className={styles.safetyLink}>FAQ</a>
            </div>
            <div id="MythsvsReality" className={styles.reality}>
                <h3 className={styles.realityTitle}>Myths vs. Reality</h3>
                <MythReality />
            </div>
            <div id="GLP1svsObesity" className={styles.glpVsObesity}>
                <h3 className={styles.glpVsObesityTitle}><span className={styles.glp1s}>GLP-1s</span> vs. <span className={styles.obesity}>Obesity</span></h3>
                <p className={styles.glpVsObesityHeader}>Below are the top side effects of GLP-1s and obesity for comparison:</p>
                <div className={styles.glpVsObesitySection}>
                    <div className={styles.glpSection}>
                        <div className={styles.glpHeader}>
                            <h3 className={styles.glpHeaderText}>GLP-1 Side Effects</h3>
                        </div>
                        <p className={styles.sideEffects}>Nausea, upset stomach, heartburn, vomiting, diarrhea, and weight loss</p>
                    </div>
                    <div className={styles.obesitySection}>
                        <div className={styles.obesityHeader}>
                            <h3 className={styles.obesityHeaderText}>Obesity Side Effects</h3>
                        </div>
                        <p className={styles.sideEffects}>Diabetes, renal disease, hypertension, sleep apnea, cancer and stroke.</p>
                    </div>
                </div>
            </div>
            <div id="CompoundingPharmacies" className={styles.compoundingSection}>
                <h3 className={styles.compoundingTitle}>Compounding Pharmacies</h3>
                <CompoundingPharmacies />
            </div>
            <div id="FAQ">
                <h3 className={styles.faqSection}>FAQ</h3>
                <FAQ />
            </div>
        </>
    );
}

export default Safety;
