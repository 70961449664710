import Header from '../.shared/header';
import Footer from '../.shared/footer';
import styles from './Contact.module.css';
import boderline from "../../assets/boderline.png"



function Contact() {

    return (
        <>
            <Header />
            <div className={styles.contact}>
                <h2>Contact Us</h2>
                <img className={styles.divider} src={boderline} alt='' />
                <div className={styles.contactMethods}>
                    <div>
                        <div className={styles.contactMethod}>
                            <div className={styles.contactType}>Patients:</div>
                            <div className={styles.contactInfo}><a href="mailto:patients@tb2.health">patients@tb2.health</a></div>
                        </div>
                        <div className={styles.contactMethod}>
                            <div className={styles.contactType}>Media inquiries:</div>
                            <div className={styles.contactInfo}><a href="mailto:social@tb2.health">social@tb2.health</a></div>
                        </div>
                        <div className={styles.contactMethod}>
                            <div className={styles.contactType}>Technical support:</div>
                            <div className={styles.contactInfo}><a href="mailto:support@tb2.health">support@tb2.health</a></div>
                        </div>
                        <div className={styles.contactMethod}>
                            <div className={styles.contactType}>Toll-free:</div>
                            <div className={styles.contactInfo}><a href="tel:18445703201">1-844-570-3201</a></div>
                        </div>
                        <div className={styles.contactMethod}>
                            <div className={styles.contactType}>Mailing address:</div>
                            <div className={styles.contactInfo}>645 Howe Ave. #1026<br />
                                Cuyahoga Falls, OH 44221</div>
                        </div>
                        <div className={styles.contactMethod}>
                            <div className={styles.contactType}>Social Media:</div>
                            <div className={styles.contactInfo}>
                                <a href="https://www.instagram.com/tb2.health" target="_blank" rel="noreferrer"><img className='social-icon' src='instagram-24.png' alt='instagram' /></a>
                                <a className='social-icon' href="https://www.facebook.com/Tb2Health" target="_blank" rel="noreferrer"><img className='social-icon' src='facebook-24.png' alt='facebook' /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Contact;