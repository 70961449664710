import styles from './Article.module.css';
import { useParams } from 'react-router-dom';
import Header from '../.shared/header.js';
import Footer from '../.shared/footer.js';
import TheImpactOfObesityOnMentalHealth from './TheImpactOfObesityOnMentalHealth.js';
import MitigatingSideEffectsOfGLP1 from './MitigatingSideEffectsOfGLP1.js';
import ExploringThePositiveEffectsOfGLP1 from './ExploringThePositiveEffectsOfGLP1.js';
import TheTB2SemaglutideChallenge from './TheTB2SemaglutideChallenge.js';
import UnlockingThePowerOfGLPs from './UnlockingThePowerOfGLPs.js';


function Article() {

    const { id } = useParams();
    let ArticleComponent;

    switch(id) {
        case 'TheImpactOfObesityOnMentalHealth':
            ArticleComponent = TheImpactOfObesityOnMentalHealth;
            break;
        case 'MitigatingSideEffectsOfGLP1':
            ArticleComponent = MitigatingSideEffectsOfGLP1;
            break;
        case 'ExploringThePositiveEffectsOfGLP1':
            ArticleComponent = ExploringThePositiveEffectsOfGLP1;
            break;
        case 'TheTB2SemaglutideChallenge':
            ArticleComponent = TheTB2SemaglutideChallenge;
            break;
        case 'UnlockingThePowerOfGLPs':
            ArticleComponent = UnlockingThePowerOfGLPs;
            break;
        default:
            ArticleComponent = () => <div>Article not found</div>;
    }

    return (
        <>
        <Header />
        <div className={styles.Article}>
            <ArticleComponent />
        </div>
        <Footer />
        </>
    );
}

export default Article;
