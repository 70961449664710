import React, { useEffect, useRef, useState } from 'react';

import styles from './Steps.module.css';

import stepimg from "../../assets/step-img.png"
import stepno1 from "../../assets/1.png"
import stepno2 from "../../assets/2.png"
import stepno3 from "../../assets/3.png"
import step1end from "../../assets/step1-end.png"
import step2end from "../../assets/step2-end.png"
import step3end from "../../assets/step3-end.png"

function Steps() {
    const [isVisible, setIsVisible] = useState(false);
    const elementRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            {
                threshold: 0.5,
            }
        );

        let currentElement = elementRef.current;
        if (currentElement) {
            observer.observe(currentElement);
        }
        return () => {
            if (currentElement) {
                observer.unobserve(currentElement);
            }
        };
    }, []);

    return (
        <>
            <div className={styles.bg2}>
                
                <img ref={elementRef}  className={styles.easySteps + (isVisible ? ' animate__animated animate__fadeInDown' : '')} src={stepimg} alt='Three easy steps' />

                <div className={styles.mainStep}>
                    <img ref={elementRef} className={styles.stepImage + (isVisible ? ' animate__animated animate__fadeInLeftBig' : '')} src={step1end} alt='Product and Payment' />
                    <div ref={elementRef} className={styles.mainStepText + (isVisible ? ' animate__animated animate__fadeInRightBig' : '')}>
                        <img className={styles.stepNumber} src={stepno1} alt='One' />
                        <div>
                            <p className={styles.stepText}>Product & Payment</p>
                            <span className={styles.stepSubtext}>Choose your weight loss path</span>
                        </div>
                    </div>
                </div>
                <div className={styles.mainStep}>
                    <img ref={elementRef} className={styles.stepImage + (isVisible ? ' animate__animated animate__fadeInLeftBig' : '')} src={step2end} alt='Registration and Intake' />                        
                    <div ref={elementRef} className={styles.mainStepText + (isVisible ? ' animate__animated animate__fadeInRightBig' : '')}>
                        <img className={styles.stepNumber} src={stepno2} alt='Two' />
                        <div>
                            <p className={styles.stepText}>Registration & Intake</p>
                            <span className={styles.stepSubtext}>Easy online registration and medical form</span>
                        </div>
                    </div>
                </div>
                <div className={styles.mainStep}>
                    <img ref={elementRef} className={styles.stepImage + (isVisible ? ' animate__animated animate__fadeInLeftBig' : '')} src={step3end} alt='Clinician and Medication' />
                    <div ref={elementRef} className={styles.mainStepText + (isVisible ? ' animate__animated animate__fadeInRightBig' : '')}>
                        <img className={styles.stepNumber} src={stepno3} alt='Three' />
                        <div>
                            <p className={styles.stepText}>Clinician & Medication</p>
                            <span className={styles.stepSubtext}>A Clinician will oversee your weight loss journey</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Steps;
