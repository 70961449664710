import React, { useState } from 'react';
import styles from './KnowledgeCenter.module.css';
import arrowdown from "../../assets/arrow.png"

function MythReality() {

    const [isVisible, setIsVisible] = useState(false);

    const handleClick = () => {
        setIsVisible(!isVisible);
    };
    const [isVisible1, setIsVisible1] = useState(false);

    const handleClick1 = () => {
        setIsVisible1(!isVisible1);
    };
    const [isVisible2, setIsVisible2] = useState(false);

    const handleClick2 = () => {
        setIsVisible2(!isVisible2);
    };
    const [isVisible3, setIsVisible3] = useState(false);

    const handleClick3 = () => {
        setIsVisible3(!isVisible3);
    };
    // const [isVisible4, setIsVisible4] = useState(false);

    // const handleClick4 = () => {
    //     setIsVisible4(!isVisible4);
    // }
    const [isVisible5, setIsVisible5] = useState(false);

    const handleClick5 = () => {
        setIsVisible5(!isVisible5);
    };

    return (
        <>
            <div className={styles.mythVsReality}>
                <div className={isVisible ? styles.openBox : styles.closedBox} onClick={handleClick}>
                    <div className={isVisible ? styles.openHeader : styles.closedHeader}>
                        <p className={isVisible ? styles.openHeaderText : styles.closedHeaderText}><span className={isVisible ? styles.openHeaderMyth : styles.closedHeaderMyth}>Myth:</span> GLP-1s are one-size-fits-all</p>
                        <img
                            src={arrowdown}
                            alt='arrowdown'
                            style={{
                                cursor: 'pointer',
                                transform: isVisible ? 'rotate(0deg)' : 'rotate(269deg)',
                            }}
                        />
                    </div>
                    {isVisible && (
                        <p className={styles.questionBody}>
                            GLP-1 drugs, widely used in diabetes treatment for some time now, have
                            recently gained approval for addressing weight-loss concerns. These
                            medications have garnered attention in various media outlets and
                            social platforms, and additional options are anticipated in the near
                            future. However, it's essential to recognize that GLP-1s may not
                            suit everyone. Qualification for these medications is subject to
                            strict criteria, and it is crucial to speak with your clinician at
                            TB2 or a trusted provider. GLP-1s do not follow a universal
                            approach, and customized medication management is imperative to
                            address your situation.
                        </p>
                    )}
                </div>
                <div className={isVisible1 ? styles.openBox : styles.closedBox} onClick={handleClick1}>
                    <div className={isVisible1 ? styles.openHeader : styles.closedHeader}>
                        <p className={isVisible1 ? styles.openHeaderText : styles.closedHeaderText}><span className={isVisible1 ? styles.openHeaderMyth : styles.closedHeaderMyth}>Myth:</span> Weight Loss is the Only Use of GLP-1s</p>
                        <img
                            src={arrowdown}
                            alt='arrowdown'
                            style={{
                                cursor: 'pointer',
                                transform: isVisible1 ? 'rotate(0deg)' : 'rotate(269deg)',
                            }}
                        />
                    </div>
                    {isVisible1 && (
                        <p className={styles.questionBody}>
                            If you have kept up with any form of news, it is easy to get caught up in the fantastic weight loss properties of GLP-1s. Beyond that, however, we should remember that the number on the scale going down is simply the tip of the iceberg. Sustained weight loss improves the quality of life, confidence, and energy and can even reduce or eliminate chronic pain for many.
                            <br /><br />
                            Focusing only on the number on the scale is short-sighted and may not adequately address the underlying issues. Hence, it is imperative to work with a great program that provides holistic support, including diet and lifestyle, and any medications a clinician may approve for you.
                        </p>
                    )}
                </div>
                <div className={isVisible2 ? styles.openBox : styles.closedBox} onClick={handleClick2}>
                    <div className={isVisible2 ? styles.openHeader : styles.closedHeader}>
                        <p className={isVisible2 ? styles.openHeaderText : styles.closedHeaderText}><span className={isVisible2 ? styles.openHeaderMyth : styles.closedHeaderMyth}>Myth:</span> The price for GLP-1s will always make them unaffordable for the masses</p>
                        <img
                            src={arrowdown}
                            alt='arrowdown'
                            style={{
                                cursor: 'pointer',
                                transform: isVisible2 ? 'rotate(0deg)' : 'rotate(269deg)',
                            }}
                        />
                    </div>
                    {isVisible2 && (
                        <p className={styles.questionBody}>
                            While it is true that the new and more effective GLP-1s, especially those indicated explicitly for long-term chronic weight loss, can undoubtedly be expensive, affordable options are available today.
                            <br /><br />
                            TB2 offers our program starting at $248/month, and in the future, we aim to bring payment plans for those who may not be able to afford it today.
                            <br /><br />
                            In a world where the entry-level phone most of us in the United States choose to have is nearly $800 - it is critical to remember that for most of our patients, this is an investment in themselves. Losing weight and taking that first step can often be the highest form of self-care, and that's why TB2 exists.
                        </p>
                    )}
                </div>
                <div className={isVisible3 ? styles.openBox : styles.closedBox} onClick={handleClick3}>
                    <div className={isVisible3 ? styles.openHeader : styles.closedHeader}>
                        <p className={isVisible3 ? styles.openHeaderText : styles.closedHeaderText}><span className={isVisible3 ? styles.openHeaderMyth : styles.closedHeaderMyth}>Myth:</span> Other over-the-counter solutions are just as effective.</p>
                        <img
                            src={arrowdown}
                            alt='arrowdown'
                            style={{
                                cursor: 'pointer',
                                transform: isVisible3 ? 'rotate(0deg)' : 'rotate(269deg)',
                            }}
                        />
                    </div>
                    {isVisible3 && (
                        <p className={styles.questionBody}>
                            We are sure you have seen the commercials by now of the "Natural Ozempic" or how "This Magic Bean Made Me Lose 200Lbs," but that is about where the wonder stops; please remember this is your health we are talking about, and it is critical to rely on advice and guidance from experts - not YouTubers.
                            <br /><br />
                            The reality is that even with GLP-1s, those patients that receive holistic and custom-tailored approaches and treatment Lose more weight on average than those who do not.
                            <br /><br />
                            There is currently no over-the-counter alternative proven to be as clinically effective as the newer GLP-1s.
                        </p>
                    )}
                </div>
                {/* <div className={isVisible4 ? styles.openBox : styles.closedBox} onClick={handleClick4}>
                    <div className={isVisible4 ? styles.openHeader : styles.closedHeader}>
                        <p>Myth: <span>GLP-1s are only for people with "Severe Obesity"</span></p>
                        <img
                            src={arrowdown}
                            alt='arrowdown'
                            style={{
                                cursor: 'pointer',
                                transform: isVisible4 ? 'rotate(0deg)' : 'rotate(269deg)',
                            }}
                        />
                    </div>
                    {isVisible4 && (
                        <p className={styles.questionBody}>
                            GLP-1s are not just for people with severe obesity. They are a great option for anyone who is overweight or obese and has struggled with weight loss in the past. They can be used in combination with other weight loss strategies, such as diet and exercise, to help you achieve your weight loss goals.
                            <br /><br />
                            If you are interested in trying GLP-1s for weight loss, talk to your healthcare provider. They can help you determine if GLP-1s are right for you and develop a treatment plan that meets your needs.
                        </p>
                    )}
                </div> */}
                <div className={isVisible5 ? styles.openBox : styles.closedBox} onClick={handleClick5}>
                    <div className={isVisible5 ? styles.openHeader : styles.closedHeader}>
                        <p className={isVisible5 ? styles.openHeaderText : styles.closedHeaderText}><span className={isVisible5 ? styles.openHeaderMyth : styles.closedHeaderMyth}>Myth:</span> GLP-1s are addictive</p>
                        <img
                            src={arrowdown}
                            alt='arrowdown'
                            style={{
                                cursor: 'pointer',
                                transform: isVisible5 ? 'rotate(0deg)' : 'rotate(269deg)',
                            }}
                        />
                    </div>
                    {isVisible5 && (
                        <p className={styles.questionBody}>
                            A handful of people do worry that using GLP-1s for weight management could lead to dependence or even addiction. That is a fallacy. GLP-1s are not an addictive substance.
                            <br /><br />
                            While they act on the GLP-1 receptors in the brain to regulate your appetite, it does not create any physical or psychological dependence.
                            <br /><br />
                            Please remember that GLP-1s require a prescription under the guidance of a healthcare professional like your TB2 clinician, who will monitor and alter your treatment plan as needed.
                            <br /><br />
                            If you have concerns about addiction or dependence, remember you can always have an open conversation with your clinician at TB2.
                        </p>
                    )}
                </div>
            </div>
        </>
    );
}

export default MythReality;
