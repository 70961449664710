import styles from './Article.module.css';

function UnlockingThePowerOfGLPs() {
    return (
        <div className={styles.ArticleContent}>
            <div>
                <img src='../images/UnlockingThePowerOfGLPs.jpg' alt='Unlocking the Power of GLPs: Your Guide to Semaglutide vs. Tirzepatide' />
            </div>
            <h1>Unlocking the Power of GLPs: Your Guide to Semaglutide vs. Tirzepatide</h1>
            <p>
                In the realm of weight loss medications, GLP-1 receptor agonists have emerged 
                as powerful tools in helping individuals achieve and maintain a healthier weight. 
                This blog post will delve into two notable options, Semaglutide and Tirzepatide, 
                exploring their mechanisms, benefits, and potential differences to help you make 
                an informed decision on your weight loss journey.
            </p>
            <h3>Understanding Semaglutide:</h3>
            <p>
                Semaglutide belongs to the GLP-1 receptor agonist class, initially developed for 
                managing type 2 diabetes. Popular medication brand names you may have heard of 
                include Ozempic® and Wegovy®. Recently, it has gained recognition for its 
                remarkable impact on weight loss. By mimicking the effects of the naturally 
                occurring hormone GLP-1, Semaglutide promotes feelings of fullness, reduces 
                appetite, and ultimately aids in shedding excess pounds.
            </p>
            <h3>Understanding Tirzepatide:</h3>
            <p>
                Tirzepatide, another member of the GLP-1 receptor agonist family, has been 
                generating excitement in the weight loss arena. Combining the actions of GLP-1 
                and glucose-dependent insulinotropic polypeptide (GIP), Popular medication 
                brand names you may have heard of include Mounjaro® and Zepbound®. Tirzepatide 
                not only addresses appetite control but also enhances insulin secretion and 
                reduces blood sugar levels.
            </p>
            <h3>Comparing Semaglutide and Tirzepatide:</h3>
            <p>
                To help you navigate these options, let's break down the comparison into major 
                categories:
            </p>
            <ol>
                <li>
                    <span>Mechanism of Action:</span>
                    <ul>
                        <li>Semaglutide primarily stimulates GLP-1 receptors.</li>
                        <li>Tirzepatide acts on both GLP-1 and GIP receptors.</li>
                    </ul>
                </li>
                <li>
                    <span>Weight Loss Efficacy:</span>
                    <ul>
                        <li>Studies show significant weight loss with both Semaglutide and Tirzepatide.</li>
                        <li>Semaglutide shows an average weight loss of 5% per month.</li>
                        <li>Tirzepatide shows an average weight loss of 8% per month.</li>
                        <li>Individual response may vary, and consultation with a healthcare professional is essential</li>
                    </ul>
                </li>
                <li>
                    <span>Administration:</span>
                    <ul>
                        <li>Both are generally administered in a simple subcutaneous shot once per week.</li>
                    </ul>
                </li>
                <li>
                    <span>Additional Benefits:</span>
                    <p>Both medications offer quite a bit of benefits beyond weight loss itself -</p>
                    <ul>
                        <li>
                            <span>Blood Sugar Control:</span> GLP-1 agonists help manage blood sugar by 
                            increasing insulin and reducing glucagon, regulating glucose levels in diabetes.
                        </li>
                        <li>
                            <span>Heart Health:</span> Some GLP-1 agonists lower the risk of heart issues
                            in people with diabetes, reducing the chance of heart attacks and strokes.
                        </li>
                        <li>
                            <span>Lower Blood Pressure:</span> GLP-1 agonists can mildly lower blood pressure,
                            benefiting those with hypertension.
                        </li>
                        <li>
                            <span>Beta Cell Support:</span> These drugs may protect and improve the function
                            of insulin-producing beta cells in the pancreas.
                        </li>
                        <li>
                            <span>Appetite Control:</span> GLP-1 agonists not only aid weight loss but also
                            curb appetite, helping with overeating.
                        </li>
                        <li>
                            <span>Gut Effects:</span> GLP-1 agonists slow down digestion, creating a feeling
                            of fullness, reducing blood sugar spikes, and potentially helping those with gastroparesis.
                        </li>
                        <li>
                            <span>Brain Protection:</span> Research suggests GLP-1 agonists may protect the brain,
                            showing potential benefits for conditions like Alzheimer's.
                        </li>

                        <li>
                            <span>Kidney Shield:</span> GLP-1 agonists may safeguard kidneys, lowering the risk of
                            diabetic kidney disease progression.
                        </li>
                        <li>
                            <span>Weight Support:</span> While aiding weight loss, GLP-1 agonists also help maintain
                            a healthier weight in the long term.
                        </li>
                    </ul>
                </li>
            </ol>
            <h3>Key Takeaways:</h3>
            <p>
                Ultimately, while tirzepatide does offer increased weight loss and additional benefits in some 
                individuals: the choice between Semaglutide and Tirzepatide should be made in consultation with 
                a healthcare professional. Each individual's unique health profile and goals will play a crucial 
                role in determining which medication aligns best with their weight loss journey. Always 
                prioritize open communication with your healthcare provider for personalized guidance.
            </p>
            <p>
                Remember, the information provided here is for educational purposes and does not substitute 
                professional medical advice. Consult your healthcare provider for personalized recommendations.
            </p>    
            <div className={styles.ArticleByLine}>Author: Toni Adamrovich, MSN, BA, APRN, FNP-C - Chief of Medicine @ TB2</div>
        </div>
    );
}

export default UnlockingThePowerOfGLPs;