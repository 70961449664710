import React, { useState } from 'react';
import Header from '../.shared/header';
import Footer from '../.shared/footer';
import ResearchCenter from "./ResearchCenter"
import Safety from "./Safety"

import styles from './KnowledgeCenter.module.css';
import programBanner from '../../assets/program-banner.png';
import boderline from "../../assets/boderline.png"
import downarrow from "../../assets/Semaglutide-down-arrow.png"

const KnowledgeCenter = () => {
    const [showSafety, setShowSafety] = useState(false);
    const [isVisible, setIsVisible] = useState(true);

    const handleSafetyClick = () => {
        setShowSafety(false);
        setIsVisible(true);
    };

    const handleResearchClick = () => {
        setShowSafety(true);
        setIsVisible(false);
    };


    return (
        <>
            <Header />
            <div className={styles.banner}>
                <div className={styles.bannerText}>
                    <div className={styles.bannerTitle}>Knowledge Center</div>
                </div>
                <img className={styles.webBanner} src={programBanner} alt="" />
                <img className={styles.divider} src={boderline} alt='' />
            </div>
            <div className={styles.container}>
                <div className={styles.buttons}>
                    <button className={isVisible ? styles.activeButton : styles.inactiveButton} onClick={handleSafetyClick}>
                        <span className={styles.buttonText}>Safety</span>
                        {isVisible && (
                            <img className={styles.buttonArrow} src={downarrow} alt='Down Arrow' />
                        )}
                    </button>
                    <button className={isVisible ? styles.inactiveButton : styles.activeButton} onClick={handleResearchClick}>
                        <span className={styles.buttonText}>Research</span>
                        {!isVisible && (
                            <img className={styles.buttonArrow} src={downarrow} alt='down arrow' />
                        )}
                    </button>
                </div>
                <img className={styles.secondDivider} src={boderline} alt='' />
                {showSafety ? <ResearchCenter /> : <Safety />}
            </div>
            <Footer />
        </>

    );
};

export default KnowledgeCenter;