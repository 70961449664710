// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SuccessStories_success__SNvkd {
    background-image: linear-gradient(to right, #23cdc7, #67bf4b);
    color: #fff;
    text-align: center;
    font-size: 30px;
    border-radius: 20px 20px 0px 0px;
    font-family: 'Cocon Bold', sans-serif;
}

.SuccessStories_success__SNvkd h2 {
    padding: 15px;
}
.SuccessStories_bg5__1wG4z {
    background: white
}
.SuccessStories_successBox__Lu7Ou {
    width: 90%;
    border-radius: 10px;
    box-shadow: 0px 0px 30px 15px #0000002b;
    margin: 0 auto;
    /* margin-top: 5%; */
    margin-bottom: 5%;
    border-radius: 20px;
}
.SuccessStories_signature__FwjBz {
    text-align: center;
    font-size: 18px;
    color: #67bf4b;
    font-weight: bold;
    margin-top: 40px;
}
.SuccessStories_testimonials__xnSqy {
    width: 60%;
    margin: 0 auto;
    word-spacing: 3px;
}


@media screen and (max-width: 992px) {
    .SuccessStories_success__SNvkd h2 {
        font-size: 24px;
    }
    .SuccessStories_testimonials__xnSqy {
        width: 80%;
        font-size: 14px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Landing/SuccessStories.module.css"],"names":[],"mappings":"AAAA;IACI,6DAA6D;IAC7D,WAAW;IACX,kBAAkB;IAClB,eAAe;IACf,gCAAgC;IAChC,qCAAqC;AACzC;;AAEA;IACI,aAAa;AACjB;AACA;IACI;AACJ;AACA;IACI,UAAU;IACV,mBAAmB;IACnB,uCAAuC;IACvC,cAAc;IACd,oBAAoB;IACpB,iBAAiB;IACjB,mBAAmB;AACvB;AACA;IACI,kBAAkB;IAClB,eAAe;IACf,cAAc;IACd,iBAAiB;IACjB,gBAAgB;AACpB;AACA;IACI,UAAU;IACV,cAAc;IACd,iBAAiB;AACrB;;;AAGA;IACI;QACI,eAAe;IACnB;IACA;QACI,UAAU;QACV,eAAe;IACnB;AACJ","sourcesContent":[".success {\n    background-image: linear-gradient(to right, #23cdc7, #67bf4b);\n    color: #fff;\n    text-align: center;\n    font-size: 30px;\n    border-radius: 20px 20px 0px 0px;\n    font-family: 'Cocon Bold', sans-serif;\n}\n\n.success h2 {\n    padding: 15px;\n}\n.bg5 {\n    background: white\n}\n.successBox {\n    width: 90%;\n    border-radius: 10px;\n    box-shadow: 0px 0px 30px 15px #0000002b;\n    margin: 0 auto;\n    /* margin-top: 5%; */\n    margin-bottom: 5%;\n    border-radius: 20px;\n}\n.signature {\n    text-align: center;\n    font-size: 18px;\n    color: #67bf4b;\n    font-weight: bold;\n    margin-top: 40px;\n}\n.testimonials {\n    width: 60%;\n    margin: 0 auto;\n    word-spacing: 3px;\n}\n\n\n@media screen and (max-width: 992px) {\n    .success h2 {\n        font-size: 24px;\n    }\n    .testimonials {\n        width: 80%;\n        font-size: 14px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"success": `SuccessStories_success__SNvkd`,
	"bg5": `SuccessStories_bg5__1wG4z`,
	"successBox": `SuccessStories_successBox__Lu7Ou`,
	"signature": `SuccessStories_signature__FwjBz`,
	"testimonials": `SuccessStories_testimonials__xnSqy`
};
export default ___CSS_LOADER_EXPORT___;
