import React, { useState } from 'react';
import styles from './KnowledgeCenter.module.css';
import arrowdown from "../../assets/arrow.png"


function CompoundingPharmacies() {
    const [isVisible, setIsVisible] = useState(false);

    const handleClick = () => {
        setIsVisible(!isVisible);
    };
    const [isVisible1, setIsVisible1] = useState(false);

    const handleClick1 = () => {
        setIsVisible1(!isVisible1);
    };
    const [isVisible2, setIsVisible2] = useState(false);

    const handleClick2 = () => {
        setIsVisible2(!isVisible2);
    };
    return (
        <>
            <div>
                <p className={styles.pharmacies}>As GLP-1s see an increase in demand, as with any medication, 
                    compounding pharmacies are called into play to help supply that demand.</p>
                <p className={styles.pharmacies}>There are many misconceptions about compounding pharmacies. For instance, the idea that the FDA does not 
                    oversee compounding pharmacies is inaccurate. Learn more below.</p>
            </div>
            <div className={styles.pharmacyQuestions}>
                <div className={isVisible1 ? styles.openBox : styles.closedBox} onClick={handleClick1}>
                    <div className={isVisible1 ? styles.openHeader : styles.closedHeader}>
                        <p className={isVisible1 ? styles.openHeaderText : styles.closedHeaderText}>How Does the Government Regulate Compounding?</p>
                        <img
                            src={arrowdown}
                            alt='arrowdown'
                            style={{
                                cursor: 'pointer',
                                transform: isVisible1 ? 'rotate(0deg)' : 'rotate(269deg)',
                            }}
                        />
                    </div>
                    {isVisible1 && (
                        <p className={styles.questionBody}>
                            Under <a href='https://www.fda.gov/drugs/human-drug-compounding/fdc-act-provisions-apply-human-drug-compounding' target='_blank' rel='noreferrer' style={{ color: "#69bf4a" }}>section 503A</a> of the FDCA, the FDA does have authority over its operations. Many oversights are in place when you work with a compounding pharmacy<br/><br/>
                            The FDA oversees compounding pharmacies and operations to ensure they meet the compounding regulations and guidelines. If a compounding operation fails to meet these guidelines or violates their rules under the FDCA, they are subject to all the requirements for conventional manufacturers. That means that non-compliant pharmacies are fined and must pass testing necessary for FDA approval BEFORE they may resume business.<br /><br />
                            Compounding pharmacies must adhere to environmental monitoring, conduct internal and external scientific literature regarding medication stability, have quality control departments, and use third-party analytic testing from FDA facilities.<br /><br />
                            The FDA ensures that no compounding pharmacy may mislabel or misrepresent compounded medications or their ingredients.<br /><br />
                            Furthermore, patients are also protected by state boards of pharmacy, not just the FDA. Compounding pharmacies are required to hold licensure in all states where they ship medications.<br/><br/>
                            However, it's important to note that compounded drugs are not FDA-approved. This means that the FDA does not verify the safety or effectiveness of compounded drugs. Instead, the FDA collaborates with state officials in a variety of ways, including sharing information about compounders who engage in poor practices that could put patients at risk.
                        </p>
                    )}
                </div>
                <div className={isVisible ? styles.openBox : styles.closedBox} onClick={handleClick}>
                    <div className={isVisible ? styles.openHeader : styles.closedHeader}>
                        <p className={isVisible ? styles.openHeaderText : styles.closedHeaderText}>How can compounding pharmacies produce Ozempic®, Wegovy® or Mounjaro®, if they are currently under patent?</p>
                        <img
                            src={arrowdown}
                            alt='arrowdown'
                            style={{
                                cursor: 'pointer',
                                transform: isVisible ? 'rotate(0deg)' : 'rotate(269deg)',
                            }}
                        />
                    </div>
                    {isVisible && (
                        <p className={styles.questionBody}>
                            GLP-1s are less than 40 amino acids, which are authorized to be produced by compounding facilities.<br /><br />
                            Furthermore, they are currently on the FDA's shortage list, which allows for the compounding of these medications. The FDA makes no distinction for a patented drug if it is on the shortage list and will not view the compounded version as an "essential copy" of the FDA-approved drug.<br /><br />
                            It is also essential to keep in mind that Novo Nordisk and Eli Lilly have patents on the finished product, not on the active pharmaceutical ingredients (API). They purchase the API from FDA-registered manufacturers just like compounding pharmacies do and then add inactive ingredients to produce the finished product.<br /><br />
                            Compounding pharmacies will sometimes produce the GLP-1s with other medications to reduce known side effects like nausea or improve weight loss and energy, thus making their finished product distinct from Novo Nordisk or Eli Lilly's finished products.
                        </p>
                    )}
                </div>
                <div className={isVisible2 ? styles.openBox : styles.closedBox}>
                    <div className={isVisible2 ? styles.openHeader : styles.closedHeader} onClick={handleClick2}>
                        <p className={isVisible2 ? styles.openHeaderText : styles.closedHeaderText}>Are Compounded Drugs More Risky?</p>
                        <img
                            src={arrowdown}
                            alt='arrowdown'
                            style={{
                                cursor: 'pointer',
                                transform: isVisible2 ? 'rotate(0deg)' : 'rotate(269deg)',
                            }}
                        />
                    </div>
                    {isVisible2 && (
                        <p className={styles.questionBody}>
                            This is a common misconception. Compounded prescription medications can even be safer for patients if they come from reputable compounding operations. That is because compounding allows patients to take valuable medications in doses appropriate and customized explicitly for them and often even omits potentially harmful additives, filler substances, dyes, or other substances.<br /><br />
                            Compounding pharmacies have so much trust that around 92% of all medicare-participating hospitals used compounded sterile drugs in 2012 and 79% of those hospitals contracted with at least one compounding facility to produce those products.
                        </p>
                    )}
                </div>
            </div>
        </>
    );
}

export default CompoundingPharmacies;
