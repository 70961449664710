import styles from './Privacy.module.css';
import Header from '../.shared/header';
import Footer from '../.shared/footer';

const PrivacyContent = () => (
    <div className={styles.privacy}>
        <h1>Privacy Policy</h1>
        <p>Effective Date: 11/13/2024</p>
        <p>This Privacy Policy outlines the type of information that Pillars for Health ("us", "we", 
            or "our") collects and records from the users ("you", "your", "user") of our Telemedicine 
            Patient Intake Form (the "Form") and how we use it.</p>
        <div className={styles.paragraph}>
            <h2>Type of Information We Collect</h2>
            <p>We collect two types of information:</p>
            <ol>
                <li><b>Personal Identifiable Information (PII):</b> When you fill out our Form, 
                    we ask for specific personal information such as your name, date of birth, 
                    address, contact number, and email. Medical information like your health 
                    history, present health conditions, medications, and lifestyle information 
                    are also required.</li>
                <li><b>Non-Personal Information (NPI):</b> We collect NPI such as browser type, the 
                    device used, the time and date of use, and other data that do not personally 
                    identify you.</li>
            </ol>
        </div>
        <div className={styles.paragraph}>
            <h2>How We Use Your Information</h2>
            <p>Your information is used to:</p>
            <ol>
                <li>Provide you with telemedicine services through our third-party service provider MyStart. 
                    Please find MyStart's privacy policy <a target='_blank' 
                        href='https://cdn.prod.website-files.com/669797812cb0c771d2033b5f/669a0da926fdbed985b67050_MyStart%20Privacy%20Policy.pdf'>here</a>.
                </li>
                <li>Communicate with you regarding your health, treatment options, and other health and 
                    treatment-related information.</li>
                <li>Improve our services.</li>
            </ol>
        </div>
        <div className={styles.paragraph}>
            <h2>How We Protect Your Information</h2>
            <p>We employ high-security measures to safeguard your PII. We comply with all applicable laws 
                and regulations, including the Health Insurance Portability and Accountability Act (HIPAA).</p>
        </div>
        <div className={styles.paragraph}>
            <h2>Information Sharing and Disclosure</h2>
            <p>We will not sell, rent, or share your PII to third parties outside Pillars for Health 
                without your consent, except for legal purposes, compliance with the law, or in a good 
                faith belief that such access, preservation, or disclosure is reasonably necessary to:</p>
            <ol>
                <li>Protect the rights, property, or safety of Pillars for Health, our users, or the public.</li>
                <li>Detect, prevent, or otherwise address fraud, security, or technical issues.</li>
            </ol>
        </div>
        <div className={styles.paragraph}>
            <h2>Data Retention</h2>
            <p>We will retain your PII only for as long as is necessary for the purposes set out in this 
                Privacy Policy.</p>
        </div>
        <div className={styles.paragraph}>
            <h2>Changes to This Privacy Policy</h2>
            <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this 
                page periodically for any changes. We will notify you of any changes by posting the new 
                Privacy Policy on this page.</p>
        </div>
        <div className={styles.paragraph}>
            <h2>Your Consent</h2>
            <p>By using our Form, you hereby consent to our Privacy Policy and agree to its terms.</p>
        </div>
        <div className={styles.paragraph}>
            <h2>Contact Us</h2>
            <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate 
                to contact us at <a href="mailto:privacy@tb2.health">privacy@tb2.health.</a></p>
        </div>
    </div>
);


function Privacy() {
    return (
        <div>
            <Header />
            <PrivacyContent />
            <Footer />
        </div>
    )
}

export default Privacy;