import Header from '../.shared/header';
import Footer from '../.shared/footer';

import styles from './Terms.module.css';


const TermsContent = () => (    
    <div className={styles.container}>
        <h1>Terms and Conditions</h1>
        <p>Last updated: 11/13/2024</p>
        <p>Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully 
            before using tb2.health ("the Website") operated by Pillars For Health, L.L.C. 
            ("us", "we", or "our").</p>
        <p>Your access to and use of the Website is conditioned upon your acceptance of and 
            compliance with these Terms. These Terms apply to all visitors, users, and others 
            who wish to access or use the Website.</p>
        <p>By accessing or using the Website, you agree to be bound by these Terms. If you 
            disagree with any part of the terms, then you do not have permission to access the Website.</p>

        <h2>1. Use of Telehealth Services</h2>
        <div className={styles.paragraph}>
            <ol>
                <li>The Website provides a platform for telehealth services, 
                    including but not limited to medical consultations, therapy 
                    sessions, and health-related information ("Services").</li>
                <li>The Services provided through the Website are not a substitute 
                    for in-person medical care or emergency services. If you are experiencing 
                    a medical emergency, please call your local emergency number immediately.</li>
            </ol>
        </div>

        <h2>2. Medical Advice</h2>
        <div className={styles.paragraph}>
            <ol>
                <li>The information provided on the Website is for general informational purposes 
                    only and is not intended as medical advice.</li>
                <li>The use of the Services does not establish a doctor-patient relationship between 
                    you and the healthcare providers on the platform.</li>
            </ol>
        </div>

        <h2>3. Privacy</h2>
        <div className={styles.paragraph}>
            <ol>
                <li>Your privacy is important to us. Please refer to 
                    our <a href='./Privacy'>Privacy Policy</a> to understand how we collect, use, and 
                    protect your personal information.</li>
            </ol>
        </div>

        <h2>4. User Accounts</h2>
        <div className={styles.paragraph}>
            <ol>
                <li>In order to access certain features of the Services, you may be required to create an account. 
                    You are responsible for maintaining the confidentiality of your account and password and for 
                    restricting access to your computer or device. You agree to accept responsibility for all 
                    activities that occur under your account or password.</li>
                <li>You must be at least 18 years old to create an account on the Website.</li>
            </ol>
        </div>

        <h2>5. User Responsibilities</h2>
        <div className={styles.paragraph}>
            <ol>
                <li>You agree to use the Services in a responsible and lawful manner and to comply with all 
                    applicable laws and regulations. You agree not to use the Services for any unlawful, harmful, 
                    or malicious purposes. </li>
                <li>You agree to provide accurate, current, and complete registration information.</li>
                <li>You commit to maintaining the accuracy of your provided information and promptly updating it 
                    when necessary.</li>
                <li>By using the Site, you confirm that you possess the legal capacity to do so and agree to abide 
                    by these Terms and Conditions.</li>
                <li>You certify that you are at least 18 years old.</li>
                <li>If you are a minor, you affirm that you have obtained parental permission to use the Site and 
                    that you are not violating any laws or regulations in your jurisdiction.</li>
                <li>You agree not to access the Site using automated or non-human methods, including bots or scripts.</li>
                <li>You will not employ the Site for any unlawful or unauthorized purposes.</li>
                <li>Your use of the Site will comply with all applicable laws and regulations and will not breach any 
                    legal obligations.</li>
            </ol>
        </div>

        <h2>6. Third-Party Service Providers</h2>
        <div className={styles.paragraph}>
            <ol>
                <li>We use third-party service providers to assist with the fulfillment of our telemedicine services. 
                    One such provider is MyStart. By using our services, you agree to the terms and conditions and 
                    privacy policies of these third-party providers. You can review MyStart's terms and conditions 
                    and privacy policy <a target='_blank' 
                        href='https://cdn.prod.website-files.com/669797812cb0c771d2033b5f/669a0d6bf8e9588b269df8b8_MyStart%20Terms%20%26%20Conditions.pdf'>here</a> and <a target='_blank' 
                        href='https://cdn.prod.website-files.com/669797812cb0c771d2033b5f/669a0da926fdbed985b67050_MyStart%20Privacy%20Policy.pdf'>here</a>.</li>
            </ol>
        </div>

        <h2>7. GLP-1 Weight Loss Program</h2>
        <div className={styles.paragraph}>
            In addition to the general terms and conditions outlined above, if you choose to enroll in 
            our GLP-1 weight loss program, the following additional terms and conditions apply:            
            <ol>
                <li>
                    <h4>Initial Eligibility Assessment and Diagnostic Tests</h4>
                    <ul>
                        <li>Before participating in the GLP-1 weight loss program, a qualified clinician 
                            will assess your eligibility based on your responses to intake questions. In 
                            some cases, the clinician may require additional diagnostic tests to make an 
                            accurate determination.</li>
                        <li>Eligibility for participation is solely at the discretion of the clinician, 
                            who may consider various factors, including your medical history, current 
                            health status, and test results.</li>
                    </ul>
                </li>
                <li>
                    <h4>Monthly Clinical Progress Review and Dosage Adjustment</h4>
                    <ul>
                        <li>Throughout your participation in the program, the clinician will conduct a 
                            comprehensive review of your progress on a monthly basis. Based on this 
                            assessment, the clinician may recommend adjustments to your GLP-1 dosage or 
                            provide additional guidance as needed.</li>
                        <li>These monthly evaluations are crucial for tailoring your treatment to your 
                            specific needs and ensuring its effectiveness.</li>
                    </ul>
                </li>
                <li>
                    <h4>Money Back Guarantee</h4>
                    <ul>
                        <li>If, after the clinician's assessment, you are found ineligible for the GLP-1 
                            weight loss program, you will be entitled to a full refund of any fees paid for 
                            the program.</li>
                        <li>Refunds will be processed within a reasonable timeframe, typically within 7-14 
                            business days.</li>
                    </ul>
                </li>
                <li>
                    <h4>Weekly Progress Updates</h4>
                    <ul>
                        <li>As a participant in the program, you may be required to provide weekly updates 
                            on your progress. This may include reporting your medication adherence, any side 
                            effects experienced, and changes in your overall health.</li>
                        <li>Timely and accurate progress updates are essential for ensuring your safety and 
                            optimizing your treatment plan.</li>
                    </ul>
                </li>
                <li>
                    <h4>Clinician's Right to Terminate</h4>
                    <ul>
                        <li>The clinician reserves the right to terminate your participation in the GLP-1 
                            weight loss program at their discretion. This may occur if your medical condition 
                            changes, if you do not adhere to program guidelines, or if there are any concerns 
                            regarding your safety or the effectiveness of the treatment.</li>
                        <li>In the event of termination by the clinician, you will not be entitled to a refund 
                            of any fees already paid for the program.</li>
                    </ul>
                </li>
                <li>
                    <h4>Patient's Right to Cancel</h4>
                    <ul>
                        <li>You have the right to cancel your participation in the GLP-1 weight loss program at 
                            any time, for any reason, without incurring any additional fees or penalties.</li>
                        <li>To cancel, please notify your clinician or our support team at patients@tb2.health.</li>
                        <li>If you decide to cancel, you will not be entitled to a refund of any fees already 
                            paid for the program.</li>
                    </ul>
                </li>
                <li>
                    <h4>Up-Front Payment</h4>
                    <ul>
                        <li>You agree to pay any applicable fees for the GLP-1 weight loss program as outlined 
                            during the enrollment process.</li>
                        <li>Fees may vary based on the services provided and the duration of your participation.</li>
                        <li>Payment is due before you gain access to the program.</li>
                    </ul>
                </li>
                <li>
                    <h4>Program Renewal and Charges</h4>
                    <ul>
                        <li>The GLP-1 weight loss program may renew automatically at the end of the selected
                            term, unless you choose to cancel it.</li>
                        <li>If you do not wish to renew your program, you must cancel it before the renewal date
                            to avoid additional charges.</li>
                    </ul>
                </li>
            </ol>
        </div>

        <h2>8. Intellectual Property</h2>
        <div className={styles.paragraph}>
            <ol>
                <li>The content on the Website, including but not limited to text, graphics, logos, and images, 
                    is protected by copyright and other intellectual property laws.</li>
                <li>You may not use, reproduce, distribute, or create derivative works based on the content without 
                    our express written consent.</li>
            </ol>
        </div>

        <h2>9. Changes to Terms and Conditions</h2>
        <div className={styles.paragraph}>
            <ol>
                <li>We reserve the right to modify or replace these Terms at any time. It is your responsibility to 
                    review these Terms periodically for changes.</li>
            </ol>
        </div>

        <h2>10. Binding Arbitration</h2>
        <div className={styles.paragraph}>
            <ol>
                <li>To expedite the resolution and manage the expenses of any dispute, disagreement, or claim 
                    arising from these Terms and Conditions (referred to individually as a "Dispute" and 
                    collectively as "Disputes"), any Dispute initiated by you or us (individually a "Party" 
                    and collectively the "Parties") shall be conclusively and exclusively settled through 
                    binding arbitration.</li>
                <li>YOU ACKNOWLEDGE THAT WITHOUT THIS PROVISION, YOU WOULD HAVE THE RIGHT TO INITIATE A LAWSUIT 
                    IN COURT AND HAVE A JURY TRIAL. The arbitration process will be initiated and conducted 
                    according to the Commercial Arbitration Rules of the American Arbitration Association ("AAA"), 
                    and where applicable, the AAA's Supplementary Procedures for Consumer-Related Disputes 
                    ("AAA Consumer Rules"), accessible at the AAA's website: <a href='https://www.adr.org' target='_blank' rel='noreferrer'>www.adr.org</a>.</li>
                <li>Your arbitration expenses and your portion of arbitrator compensation will be subject to the 
                    guidelines established by the AAA Consumer Rules and may also be limited by those rules. 
                    Should the arbitrator consider these costs excessive, we will bear the responsibility for 
                    covering all arbitration fees and costs.</li>
                <li>The arbitration proceedings may occur in person, through document submissions, over the phone, 
                    or online. The arbitrator's decision will be documented in writing, with the exception that a 
                    statement of reasons need not be provided unless explicitly requested by either Party. The 
                    arbitrator must adhere to relevant legal principles, and any awarded decision may be contested 
                    if the arbitrator fails to do so.</li>
                <li>Unless mandated otherwise by applicable AAA regulations or law, the arbitration proceedings 
                    will take place in [name of county], [name of state]. Aside from the provisions outlined herein, 
                    the Parties maintain the right to seek resolution through litigation in court, specifically for 
                    compelling arbitration, postponing proceedings while arbitration is pending, or to validate, 
                    amend, annul, or enter a judgment based on the arbitrator's decision.</li>
                <li>Should a Dispute be processed in court instead of arbitration for any reason, such Dispute will 
                    be initiated or pursued within the state and federal courts located in [name of county], [name 
                    of state]. By agreeing to these terms, the Parties hereby waive any defenses related to lack of 
                    personal jurisdiction or forum non conveniens, thereby consenting to the venue and jurisdiction 
                    of such state and federal courts.</li>
                <li>Application of the United Nations Convention on Contracts for the International Sale of Goods 
                    and the Uniform Computer Information Transaction Act (UCITA) are expressly excluded from the 
                    scope of these Terms and Conditions. Under no circumstances shall any Dispute initiated by 
                    either Party regarding the Site or Services be filed more than 1 year after the origination of 
                    the cause of action.</li>
                <li>Should this provision be deemed unlawful or unenforceable, neither Party shall choose to 
                    arbitrate any Dispute falling under that portion of this provision, and such Dispute shall be 
                    resolved by a competent court listed within the jurisdiction above. The Parties mutually agree 
                    to subject themselves to the personal jurisdiction of that court.</li>
            </ol>
        </div>

        <h2>11. Disclaimer</h2>
        <div className={styles.paragraph}>
            THE SITE IS PROVIDED "AS-IS" AND "AS-AVAILABLE." YOUR USE OF THE SITE AND OUR SERVICES IS ENTIRELY AT 
            YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WE DISCLAIM ALL WARRANTIES, WHETHER 
            EXPRESS OR IMPLIED, RELATED TO THE SITE AND YOUR USE OF IT, INCLUDING BUT NOT LIMITED TO IMPLIED 
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO 
            WARRANTIES OR REPRESENTATIONS REGARDING THE ACCURACY OR COMPLETENESS OF THE SITE'S CONTENT OR THE 
            CONTENT OF ANY LINKED WEBSITES. WE ASSUME NO LIABILITY OR RESPONSIBILITY FOR: 
            <ol className={styles.numberedList}>
                <li>ANY ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS</li>
                <li>ANY PERSONAL INJURY OR PROPERTY DAMAGE ARISING FROM YOUR ACCESS TO AND USE OF THE SITE</li>
                <li>ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY PERSONAL OR FINANCIAL 
                    INFORMATION STORED THEREIN</li>
                <li>ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SITE</li>
                <li>ANY BUGS, VIRUSES, TROJAN HORSES, OR SIMILAR MALICIOUS CODE TRANSMITTED THROUGH THE SITE BY 
                    THIRD PARTIES</li>
                <li>ANY ERRORS OR OMISSIONS IN THE SITE'S CONTENT AND MATERIALS, OR FOR ANY LOSS OR DAMAGE INCURRED 
                    AS A RESULT OF USING ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE THROUGH THE 
                    SITE</li>
            </ol>
        </div>
        <div className={styles.paragraph}>
            WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED 
            OR OFFERED BY THIRD PARTIES THROUGH THE SITE OR ANY LINKED WEBSITE. FURTHERMORE, WE WILL NOT BE A PARTY 
            TO OR IN ANY WAY RESPONSIBLE FOR MONITORING TRANSACTIONS BETWEEN YOU AND THIRD-PARTY PROVIDERS OF 
            PRODUCTS OR SERVICES.
        </div>
        <div className={styles.paragraph}>
            SIMILARLY, AS WITH THE PURCHASE OF ANY PRODUCT OR SERVICE THROUGH ANY MEDIUM OR ENVIRONMENT, YOU SHOULD 
            EXERCISE CAUTION, USE YOUR BEST JUDGMENT, AND MAKE INFORMED DECISIONS.
        </div>

        <h2>12. Limitations of Liability</h2>
        <div className={styles.paragraph}>
            WE, ALONG WITH OUR DIRECTORS, EMPLOYEES, AND AGENTS, SHALL NOT BE LIABLE TO YOU OR ANY THIRD PARTY FOR 
            ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES. THESE DAMAGES 
            INCLUDE BUT ARE NOT LIMITED TO LOST PROFITS, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES THAT MAY 
            ARISE FROM YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        </div>
        <div className={styles.paragraph}>
            [WITHOUT REGARD TO ANY CONTRARY PROVISIONS HEREIN, OUR LIABILITY TO YOU FOR ANY REASON WHATSOEVER AND 
            REGARDLESS OF THE FORM OF THE LEGAL ACTION SHALL ALWAYS BE CAPPED AT [THE LESSER OF] [THE AMOUNT YOU PAID, 
            IF ANY, TO US DURING THE <b>12 MONTH PERIOD BEFORE THE OCCURRENCE OF THE INCIDENT GIVING RISE TO THE CLAIM [OR] 
            $1,000.</b> CERTAIN STATE LAWS MAY NOT ALLOW THE LIMITATION OF IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION 
            OF CERTAIN DAMAGES. 
        </div>
        <div className={styles.paragraph}>
            IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT BE APPLICABLE, AND YOU 
            MAY BE ENTITLED TO ADDITIONAL RIGHTS. 
        </div>

        <h2>13. Indemnification</h2>
        <div className={styles.paragraph}>
            You hereby commit to defend, indemnify, and shield us from any loss, damage, liability, claim, or demand. This 
            extends to our subsidiaries, affiliates, as well as all officers, agents, partners, and employees associated with 
            us. This coverage includes reasonable attorneys' fees and expenses incurred as a result of:
            <ol>
                <li>Your Contributions</li>
                <li>Your use of the Site</li>
                <li>Any violation of these Terms and Conditions</li>
                <li>Any breach of the representations and warranties you've made in these Terms and Conditions</li>
                <li>Any infringement upon the rights of a third party, including intellectual property rights</li>
                <li>Any overt harmful actions directed toward another user of the Site with whom you interacted via the Site</li>
            </ol>
        </div>
        <div className={styles.paragraph}>        
            However, we retain the right to, at your cost, assume exclusive control and defense of any matter requiring your 
            indemnification. You agree to cooperate, at your expense, in our defense against such claims. We will make 
            reasonable attempts to notify you of any such claims, actions, or proceedings subject to this indemnification, 
            once we are made aware of them.
        </div>

        <h2>14. Electronic Communications, Transactions, and Signatures</h2>
        <div className={styles.paragraph}>
            Your interaction with the Site, sending us emails, and filling out online forms constitute forms of electronic 
            communication. By continuing to use these methods, you grant consent to receive electronic communications. 
            Furthermore, you acknowledge that any agreements, notifications, disclosures, and other communications we furnish 
            to you electronically, whether via email or on the Site, fulfill any legal necessity for such communications to 
            be in writing.
        </div>
        <div className={styles.paragraph}>
            Additionally, you hereby provide your consent for the utilization of electronic signatures, contracts, orders, 
            and other records, as well as for the electronic distribution of notices, policies, and records pertaining to 
            transactions conducted by us or through the Site.
        </div>
        <div className={styles.paragraph}>
            Through this agreement, you waive any rights or prerequisites under any laws, statutes, regulations, ordinances, 
            or other legislative measures in any jurisdiction that require an original signature or the preservation or 
            provision of non-electronic records. This also includes matters relating to payments or the issuance of credits 
            by any means other than electronic methods.
        </div>

        <h2>15. Miscellaneous</h2>
        <div className={styles.paragraph}>
            These Terms and Conditions, alongside any policies or operational guidelines we post on the Site, encompass the 
            entire agreement and comprehension between you and us. Our failure to exercise or enforce any right or provision 
            stipulated in these Terms and Conditions shall not be deemed a waiver of said right or provision.
        </div>
        <div className={styles.paragraph}>
            These Terms and Conditions are upheld to the fullest extent allowable by law. We retain the prerogative to assign 
            any or all of our rights and responsibilities to others whenever necessary. We shall not be held accountable or 
            liable for any loss, damage, delay, or inability to act, resulting from any circumstances beyond our reasonable control.
        </div>
        <div className={styles.paragraph}>
            Should any section or component of these Terms and Conditions be determined to be unlawful, void, or unenforceable, 
            such portion shall be considered separable from these Terms and Conditions and shall not impact the validity and 
            enforceability of the remaining provisions.
        </div>
        <div className={styles.paragraph}>        
            This agreement does not establish a joint venture, partnership, employment relationship, or agency affiliation between 
            you and us, as a result of these Terms and Conditions or your use of the Site. You agree that these Terms and Conditions 
            will not be construed against us on the grounds of our role in drafting them.
        </div>
        <div className={styles.paragraph}>        
            By using the Site, you relinquish any and all defenses you might otherwise have based on the electronic format of these 
            Terms and Conditions and the absence of physical signatures by the parties involved to execute these Terms and Conditions.
        </div>

        <h2>16. Governing Law</h2>
        <div className={styles.paragraph}>
            These Terms shall be governed by and construed in accordance with the laws of Delaware, without regard to its conflict 
            of law provisions.
        </div>

        If you have any questions or concerns about these Terms and Conditions, please contact us at privacy@tb2.health
    </div>
);

function Terms() {
    return (
        <div>
            <Header />
            <TermsContent />
            <Footer />
        </div>
    );
}

export default Terms;