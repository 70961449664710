import React, { useEffect, useRef, useState } from 'react';
import styles from './AboutUs.module.css';
import Header from '../.shared/header';
import Footer from '../.shared/footer';

import boderline from "../../assets/boderline.png"
import downarrow from "../../assets/Semaglutide-down-arrow.png"
import SingleCare from "../../assets/SingleCare.png"
import SingleCare350 from "../../assets/SingleCare350.png"
import AuthorityMagazine from "../../assets/Authority-Magazine.png"
import AuthorityMagazine350 from "../../assets/Authority-Magazine350.png"
import CEOWeekly from "../../assets/CEO-Weekly.png"
import CEOWeekly350 from "../../assets/CEO-Weekly350.png"
import NYWeekly from "../../assets/NY-Weekly.png"
import BodyNetwork from "../../assets/Body-Network.png";
import DailyMail from "../../assets/daily-mail.png";
import DailyMail350 from "../../assets/daily-mail350.png";
import MedicalNewsToday from "../../assets/MedicalNewsToday.png";
import MedicalNewsToday350 from "../../assets/MedicalNewsToday350.png";
import firstteammember from "../../assets/first-team-member.png"
import secondteammember from "../../assets/second-team-member.png"

const AboutUs = () => {

    const [isVisible, setIsVisible] = useState(false);
    const elementRef = useRef(null);

    const singleCareImg = window.innerWidth < 400 ? SingleCare350 : SingleCare;
    const authorityMagazineImg = window.innerWidth < 400 ? AuthorityMagazine350 : AuthorityMagazine;
    const ceoWeeklyImg = window.innerWidth < 400 ? CEOWeekly350 : CEOWeekly;
    const dailyMailImg = window.innerWidth < 400 ? DailyMail350 : DailyMail;
    const medicalNewsTodayImg = window.innerWidth < 400 ? MedicalNewsToday350 : MedicalNewsToday;

    const handleHover = (e) => {
        e.target.classList.add('animate__animated', 'animate__pulse', styles.glow);
    };
    const handleHoverEnd = (e) => {
        e.target.classList.remove('animate__animated', 'animate__pulse', styles.glow);
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            {
                threshold: 0.5,
            }
        );
  
        let currentElement = elementRef.current;
        if (currentElement) {
            observer.observe(currentElement);
        }
        return () => {
            if (currentElement) {
                observer.unobserve(currentElement);
            }
        };
    }, []);


    return (
        <>
            <Header />
            <div className={styles.banner}>
                <div className={styles.bannerText}>
                    <div className={styles.bannerTitle}>About Us</div>
                </div>
                <img className={styles.divider} src={boderline} alt='' />
            </div>
            <div className={styles.container}>
                <div className={styles.buttons}>
                    <a href="#MeetUs">
                        <button ref={elementRef} onMouseEnter={handleHover} onMouseLeave={handleHoverEnd} className={styles.Safety}>
                            <span className={styles.buttonLabel}>Meet Us</span><img src={downarrow} alt='' />
                        </button>
                    </a>
                    <a href="#Articles">
                        <button ref={elementRef} onMouseEnter={handleHover} onMouseLeave={handleHoverEnd} className={styles.Safety}>
                            <span className={styles.buttonLabel}>Articles</span><img src={downarrow} alt='' />
                        </button>
                    </a>
                </div>
                <div className={styles.featured}>
                    <h4 className={styles.featuredTitle}>Featured In:</h4>
                    <div className={styles.teamBrands}>
                        <img src={dailyMailImg} alt='' />
                        <img src={authorityMagazineImg} alt='' />
                        <img src={medicalNewsTodayImg} alt='' />
                        <img src={NYWeekly} alt='' />
                        <img src={BodyNetwork} alt='' />
                    </div>
                </div>
                <div id="MeetUs" className={styles.bubble}>Meet Our Team</div>
                <div className={styles.teamMember}>
                    <img src={firstteammember} alt='' />
                    <div className={styles.teamMemberText}>
                        <h4>Paul Moss</h4>
                        <h6>CEO & Co-founder</h6>
                        <svg width="56" height="4" viewBox="0 0 56 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M56 0V4H0V0H56Z" fill="#69BF4A" />
                        </svg>
                        <p><span className={styles.firstSentence}>I’m Paul Moss, the CEO and co-founder of tb2.health.</span> We’re working hard to make GLP-1 medications more accessible to those who need them most through our direct-to-patient weight loss prescription solutions. While I’ve had some success in business, I’m also deeply committed to giving back and sharing what I’ve learned. You might have come across my thoughts and experiences in publications like FinTech Times, the Wall Street Journal, and USA Today, where I’ve had the opportunity to contribute.</p>
                    </div>
                </div>
                <div className={styles.teamMember}>
                    <img src={secondteammember} alt='' />
                    <div className={styles.teamMemberText}>
                        <h4>Antoni Adamrovich</h4>
                        <span>MSN, BA, APRN, FNP-C</span>
                        <h6>Chief of Medicine & Co-founder</h6>
                        <svg width="56" height="4" viewBox="0 0 56 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M56 0V4H0V0H56Z" fill="#69BF4A" />
                        </svg>
                        <p><span className={styles.firstSentence}>I’m Antoni Adamrovich, the Chief of Medicine and co-founder of tb2.health.</span> The future of medicated weight loss is heading towards fascinating advancements. Currently, efforts are focused on developing more efficacious oral GLP-1 medications to eliminate the need for injections, despite the challenge of managing their gastrointestinal side effects. Medications act as tools to modify your eating behaviors by affecting how you react to food, emphasizing the importance of monitoring calories and macros. The goal is not lifelong dependence on these drugs but to use them as a means to establish healthier habits.</p>
                    </div>
                </div>
                <div id="Articles" className={styles.bubble}>Articles / Links</div>
                <div className={styles.articlesContainer}>
                    <div className={styles.brandCardMain} onClick={() => {window.open('https://medium.com/authority-magazine/paul-moss-and-antoni-adamrovich-of-tb2-health-49f6d473922c')}}>
                        <div className={styles.brandCard} ref={elementRef} onMouseEnter={handleHover} onMouseLeave={handleHoverEnd}>
                            <img src={authorityMagazineImg} alt='' />
                        </div>
                        <p>Paul Moss and Antoni Adamrovich of tb2.health</p>
                    </div>
                    <div className={styles.brandCardMain} onClick={() => {window.open('https://ceoweekly.com/meet-paul-moss-ceo-and-co-founder-of-tb2-health/')}}>
                        <div className={styles.brandCard} ref={elementRef} onMouseEnter={handleHover} onMouseLeave={handleHoverEnd}>
                            <img src={ceoWeeklyImg} alt='' />
                        </div>
                        <p>Meet Paul Moss, CEO and Co-founder of TB2.Health</p>
                    </div>
                    <div className={styles.brandCardMain} onClick={() => {window.open('https://www.singlecare.com/blog/ozempic-diet/')}}>
                        <div className={styles.brandCard} ref={elementRef} onMouseEnter={handleHover} onMouseLeave={handleHoverEnd}>
                            <img src={singleCareImg} alt='' />
                        </div>
                        <p>Ozempic diet: What to eat for weight loss</p>
                    </div>
                </div>
            </div>
            <Footer />
        </>

    );
};

export default AboutUs;