import { useRef, useEffect } from 'react';
import styles from './Checkout.module.css';

const PhoneNumber = ({formData, setFormData, setValidationErrors}) => {
    const part1Ref = useRef();
    const part2Ref = useRef();
    const part3Ref = useRef();
    const phone_ErrorRef = useRef();

    useEffect(() => {
        part1Ref.current.value = formData.cell ? formData.cell.substr(0, 3) : '';
        part2Ref.current.value = formData.cell ? formData.cell.substr(3, 3) : '';
        part3Ref.current.value = formData.cell ? formData.cell.substr(6, 4) : '';
        }, [formData]); 
  
    function _handleChange () {
        setFormData((prevFormData) => ({ ...prevFormData, cell: part1Ref.current.value + part2Ref.current.value + part3Ref.current.value }));
    };

    function _handleKeyUp(partRef) {
        if(partRef.keyCode) {
            // 'delete' and input is empty, then go to previous input
            if (partRef.keyCode === 8 && !partRef.target.value) {
                var previous = partRef.target.previousElementSibling;
                if (previous && previous.tagName.toLowerCase() === 'input') {
                  previous.focus();
                }
            }

            // if tab, left, or right just exit
            if (partRef.keyCode === 9 || partRef.keyCode === 16 ||
                (partRef.keyCode >= 35 && partRef.keyCode <= 40)) {
                return;
            }

            var maxLength = parseInt(partRef.target.attributes['maxlength'].value, 10);
            var myLength = partRef.target.value.length;
            if (myLength >= maxLength) {
                var next = partRef.target.nextElementSibling;
                if (next && next.tagName.toLowerCase() === 'input') {
                    next.focus();
                }
            }

            var nums = part1Ref.current.value + part2Ref.current.value + part3Ref.current.value;
            nums = nums.replace(/\D/g,'');
            if (nums.length < 10) {
                phone_ErrorRef.current.style.display = 'block';
                setValidationErrors(errors => ({ ...errors, cell: 'Please enter a valid 10-digit phone number' }));
            } 
            else {
                phone_ErrorRef.current.style.display = 'none';
                setValidationErrors(errors => {
                    const {cell: _, ...rest} = errors;
                    return rest;
                })
            }
        }
    };
  
    function renderPart(partRef, length, placeholder) {
        return (
          <input
            type='number' className={styles.phonePart}
            ref={partRef} maxLength={length} minLength={length}
            onChange={_handleChange}
            placeholder={placeholder}
            onKeyUp={_handleKeyUp} required />
        );
    };
 
    return (
        <>
            <div className={styles.phoneContainer}>
                <div>Phone:</div>
                <span>{renderPart(part1Ref, 3, '###')}{renderPart(part2Ref, 3, '###')}{renderPart(part3Ref, 4, '####')}</span>
            </div>
            <div ref={phone_ErrorRef} className={styles.fieldError} style={{display:'none'}}>Invalid phone</div>
        </>
    );
};

export default PhoneNumber;
