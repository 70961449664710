import Header from '../.shared/header';
import Footer from '../.shared/footer';
import CheckoutForm from './CheckoutForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import styles from './Checkout.module.css';
import boderline from "../../assets/boderline.png";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const Checkout = () => {  
  
  return (
    <>
      <Header />
      <div className={styles.banner}>
        <div className={styles.bannerText}>
          <div className={styles.bannerSubtitle}>You're Almost There!</div>
          <div className={styles.bannerTitle}>Checkout</div>
        </div>
        <img className={styles.headerRule} src={boderline} alt='' />
      </div>
      <Elements stripe={stripePromise}>
        <CheckoutForm />
      </Elements>

      <Footer />
    </>
  );
};

export default Checkout;