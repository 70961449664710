import React, { useState } from 'react';
import styles from './KnowledgeCenter.module.css';
import arrowdown from '../../assets/arrow.png';

function FAQ() {
    const [isVisible, setIsVisible] = useState(false);

    const handleClick = () => {
        setIsVisible(!isVisible);
    };
    const [isVisible1, setIsVisible1] = useState(false);

    const handleClick1 = () => {
        setIsVisible1(!isVisible1);
    };
    const [isVisible2, setIsVisible2] = useState(false);

    const handleClick2 = () => {
        setIsVisible2(!isVisible2);
    };

    return (
        <>
            <div className={styles.faqQuestions}>
                <p className={styles.faqHeader}>Below are some of the most common questions we receive about GLP-1s.<br/> If you have any additional questions, please email us at <span style={{ color: "#69bf4a" }}>social@tb2.health</span></p>
                <div className={isVisible ? styles.openBox : styles.closedBox} onClick={handleClick}>
                    <div className={isVisible ? styles.openHeader : styles.closedHeader}>
                        <p className={isVisible ? styles.openHeaderText : styles.closedHeaderText}>What program should I sign up for?</p>
                        <img
                            src={arrowdown}
                            alt='arrowdown'
                            style={{
                                cursor: 'pointer',
                                transform: isVisible ? 'rotate(0deg)' : 'rotate(269deg)',
                            }}
                        />
                    </div>
                    {isVisible && (
                        <p className={styles.questionBody}>
                            Desired weight loss and budget are some of the main factors in the decision. Don't forget to consider that when you are eating less you may save money on food, which may offset the cost of the program. <br /><br />
                            If you need to lose 5-10% of your body weight, semaglutide is a good option. If you need to lose more than 10% of your body weight, tirzepatide is a better option. <br /><br />
                            If you are new to GLP-1s or tentative about how you will tolerate the medication, consider starting with a monthly plan. If you know you are in it for the long haul, go for quarterly to save money.<br /><br />
                            Ultimately, based on your intake form, the clinician will determine if you are eligible and if your desired program is best for you. If you are not eligible, you will be refunded. You can also change your program at any time.
                        </p>
                    )}
                </div>
                <div className={isVisible1 ? styles.openBox : styles.closedBox} onClick={handleClick1}>
                    <div className={isVisible1 ? styles.openHeader : styles.closedHeader}>
                        <p className={isVisible1 ? styles.openHeaderText : styles.closedHeaderText}>What about dosage and frequency?</p>
                        <img
                            src={arrowdown}
                            alt='arrowdown'
                            style={{
                                cursor: 'pointer',
                                transform: isVisible1 ? 'rotate(0deg)' : 'rotate(269deg)',
                            }}
                        />
                    </div>
                    {isVisible1 && (
                        <p className={styles.questionBody}>
                            Per clinician instructions, you or a trusted friend will administer the GLP-1 medication through subcutaneous injection, typically once a week.<br/><br/>
                            If you are new to GLP-1s you will start slowly to see how you tolerate it. The dosage of semaglutide will start at 0.25mg a week, and the dosage of tirzepatide will begin at 2.5mg a week.<br /> <br />
                            Every month, you and your clinician will review your progress and adjust the dosage as needed. The manufacturers suggest the same dose for a month and then increasing the dose every month until the maximum dosage is reached.<br/><br/>
                            Each patient is different, and the clinician will determine the best dosage and frequency for you. If you have any questions, please reach out to your clinician.
                        </p>
                    )}
                </div>
                <div className={isVisible2 ? styles.openBox : styles.closedBox} onClick={handleClick2}>
                    <div className={isVisible2 ? styles.openHeader : styles.closedHeader}>
                        <p className={isVisible2 ? styles.openHeaderText : styles.closedHeaderText}>What are the most common side effects of GLP-1s?</p>
                        <img
                            src={arrowdown}
                            alt='arrowdown'
                            style={{
                                cursor: 'pointer',
                                transform: isVisible2 ? 'rotate(0deg)' : 'rotate(269deg)',
                            }}
                        />
                    </div>
                    {isVisible2 && (
                        <p className={styles.questionBody}>
                            Nausea is the most common side effect, which seems more prevalent for females. For most patients, it is not long-lasting, sometimes occurring when first starting the medication or increasing the dose. <br /><br />
                            In some patients, there may be sporadic vomiting, diarrhea, upset stomach, abdominal cramps, or sulfur burps. This is more common in patients with a history of acid reflux who do not adequately hydrate.<br /><br />
                            When a patient experiences any of these side effects, the clinician may evaluate possible prescriptive treatment (like anti-nausea medications)<br/><br/>   
                            If the side effects are mild, the clinician may recommend focusing on diet and hydration, eating a protein-rich diet with adequate fiber and monitoring the number of calories consumed. It is very important to get enough water and calories to keep your body functioning as it should.<br/><br/>
                            Please keep in mind that all side effects need to be reported to your clinician immediately. These are general recommendations and not intended to replace the advice of your clinician.
                        </p>
                    )}
                </div>
            </div>
        </>
    );
}

export default FAQ;
