import styles from './Article.module.css';

function TheImpactOfObesityOnMentalHealth() {
    return (
        <div className={styles.ArticleContent}>
            <div>
                <img src='../images/TheImpactOfObesityOnMentalHealth.jpg' alt='The Impact of Obesity On Mental Health' />
            </div>
            <h1>The Impact of Obesity on Mental Health</h1>
            <div>
                <p>
                A patient once said that if men think about sex every 7 seconds, I think about 
                my weight every 3 seconds. I recently witnessed a family member at one 
                Thanksgiving dinner have a full-blown breakdown after looking at everyone 
                else`s plentifully loaded plates and her own meager plate. She had tried 
                dieting and exercising but was still unable to lose weight and could barely 
                stand to look at herself. The effects of obesity are often thought about 
                regarding physical health, but the burden on mental health is addressed far less.
                </p>
                <p>
                Overweight and obesity, a global health concern affecting 2 billion people 
                worldwide, is not solely a physical issue. Its far-reaching impact extends 
                to mental health, manifesting in various ways that significantly influence 
                an individual's psychological well-being. The correlation between obesity 
                and mental health has garnered attention from researchers and healthcare 
                professionals, shedding light on the profound implications this relationship 
                holds. Let's look at the substantial impact of obesity on mental health:
                </p>
                <ol>
                    <li>
                        <span>Depression and Anxiety</span>
                        <ul>
                            <li>
                            <span>Depression:</span> Studies show a strong association between obesity 
                            and depression. According to the World Health Organization 
                            (WHO), obese individuals are about 55% more likely to develop 
                            depression compared to those with a healthier weight.
                            </li>
                            <li>
                            <span>Anxiety Disorders:</span> Research indicates a heightened risk of anxiety 
                            disorders in obese individuals, with approximately 25% higher odds 
                            of developing anxiety-related conditions compared to non-obese 
                            individuals.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span>Low Self-Esteem and Body Image Issues</span>
                        <ul>
                            <li>
                            <span>Self-Esteem:</span> Individuals with obesity often struggle with lower 
                            self-esteem. Research from the National Institute of Mental Health 
                            indicates that adolescents with obesity are more prone to 
                            experiencing low self-esteem, leading to emotional distress and 
                            negative perceptions about themselves.
                            </li>
                            <li>
                            <span>Body Image:</span> Studies reveal that a significant percentage of obese 
                            individuals experience dissatisfaction with their body image. 
                            According to the American Psychological Association, around 
                            50-60% of adults with obesity report body dissatisfaction, which 
                            contributes to psychological distress.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span>Social Stigmatization and Isolation</span>
                        <ul>
                            <li>
                            <span>Social Stigma:</span> Obese individuals face stigma and discrimination in 
                            various settings, including employment, education, and healthcare. 
                            Reports from Obesity Action Coalition highlight that approximately 
                            40% of individuals with obesity experience weight-related discrimination, 
                            leading to increased stress and mental health challenges.
                            </li>
                            <li>
                            <span>Isolation:</span> The stigma attached to obesity often leads to social 
                            isolation. Research published in the International Journal of Obesity 
                            revealed that obese individuals are more likely to report feelings of 
                            loneliness and social exclusion, impacting their mental health.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span>Eating Disorders and Cognitive Function</span>
                        <ul>
                            <li>
                            <span>Eating Disorders:</span> Obesity is a risk factor for developing eating 
                            disorders, particularly binge eating disorder. The National Eating 
                            Disorders Association estimates that up to 30% of individuals seeking 
                            treatment for obesity have symptoms indicative of binge eating disorder.
                            </li>
                            <li>
                            <span>Cognitive Function:</span> Obesity can impair cognitive function. A study 
                            published in Neurology found that middle-aged individuals with obesity 
                            demonstrated a decline in cognitive abilities such as memory and attention 
                            compared to those with a healthy weight.
                            </li>
                        </ul>
                    </li>
                </ol>
                <h3>Conclusion:</h3>
                <p>
                    The statistics underscore the intricate relationship between obesity and mental 
                    health. Beyond physical health implications, obesity significantly affects mental 
                    well-being, leading to conditions such as depression, anxiety, low self-esteem, 
                    social isolation, and impaired cognitive function. Recognizing and addressing the 
                    mental health aspects of obesity are crucial for comprehensive healthcare strategies.
                </p>
                <p>
                By fostering a holistic approach that integrates mental health support with obesity 
                management, individuals can receive the necessary guidance, support, and treatment to 
                address both physical and psychological aspects, ultimately improving overall well-being. 
                Awareness, early intervention, and a compassionate understanding of this intricate 
                interplay between obesity and mental health are pivotal in promoting a healthier and more 
                inclusive society.
                </p>
            </div>
            <div className={styles.ArticleByLine}>Author: Toni Adamrovich, MSN, BA, APRN, FNP-C - Chief of Medicine @ TB2</div>
        </div>
    );
}

export default TheImpactOfObesityOnMentalHealth;