import styles from './Login.module.css';

import { useEffect } from 'react';

const Login = () => {
    
        useEffect(() => {
    
            window.location.href = 'https://secure.gethealthie.com/go/tb2';
            
        }, []);
        
        return (
            <div className={styles.login}>
                <h3>Redirecting to our secure portal</h3>
            </div>
        )
    }

export default Login;