import { React, useEffect } from 'react';
import Header from '../.shared/header';
import Footer from '../.shared/footer';
import Steps from './Steps';
import Support from './Support';
import SuccessStories from './SuccessStories';
// import QuestionHero from './QuestionHero';
//import VialHero from './VialHero';
import WomanHero from './WomanHero';
import StartMyJourney from './StartMyJourney';



    

function Landing() {

    return (
        <>
            <Header />
            {/* <QuestionHero /> */}
            {/* <VialHero /> */}
            <WomanHero />
            <Steps />
            <Support />
            <StartMyJourney />
            <SuccessStories />
            <Footer />
        </>
    );
}

export default Landing;
