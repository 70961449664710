// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StartMyJourney_startMyJourney__3yIdb {
    background-color: transparent;
    text-align: center;
}

.StartMyJourney_journeyButton__nSsWp {
    background-color: #69bf4a;
    width: 33%;
    text-align: center;
    padding: 12px;
    color: #fff;
    font-weight: bold;
    font-size: 24px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: center;
    font-family: 'Cocon Bold', sans-serif;
    cursor: pointer;
    margin: 50px auto;
    cursor: pointer !important;
    z-index: 999;
}
.StartMyJourney_buttonArrow__8mtJs {
    margin-left: 10px;
    width: 36px;
}
.StartMyJourney_glow__M9uyM {
    box-shadow: 0 0 30px #00ff00;
}

@media screen and (max-width: 992px) {
    .StartMyJourney_journeyButton__nSsWp {
        margin: 20px auto;
        width: 49%;
    }
}
@media screen and (max-width: 768px) {
    .StartMyJourney_journeyButton__nSsWp {
        margin: 15px auto;
        width: 68%;
        font-size: 20px;
    }
}
@media screen and (max-width: 350px) {
    .StartMyJourney_journeyButton__nSsWp {
        padding: 8px;
        font-size: 16px;
        gap: 0px;
    }
    .StartMyJourney_buttonArrow__8mtJs {
        width: 25px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Landing/StartMyJourney.module.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,UAAU;IACV,kBAAkB;IAClB,aAAa;IACb,WAAW;IACX,iBAAiB;IACjB,eAAe;IACf,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,uBAAuB;IACvB,qCAAqC;IACrC,eAAe;IACf,iBAAiB;IACjB,0BAA0B;IAC1B,YAAY;AAChB;AACA;IACI,iBAAiB;IACjB,WAAW;AACf;AACA;IACI,4BAA4B;AAChC;;AAEA;IACI;QACI,iBAAiB;QACjB,UAAU;IACd;AACJ;AACA;IACI;QACI,iBAAiB;QACjB,UAAU;QACV,eAAe;IACnB;AACJ;AACA;IACI;QACI,YAAY;QACZ,eAAe;QACf,QAAQ;IACZ;IACA;QACI,WAAW;IACf;AACJ","sourcesContent":[".startMyJourney {\n    background-color: transparent;\n    text-align: center;\n}\n\n.journeyButton {\n    background-color: #69bf4a;\n    width: 33%;\n    text-align: center;\n    padding: 12px;\n    color: #fff;\n    font-weight: bold;\n    font-size: 24px;\n    border-radius: 25px;\n    display: flex;\n    align-items: center;\n    gap: 16px;\n    justify-content: center;\n    font-family: 'Cocon Bold', sans-serif;\n    cursor: pointer;\n    margin: 50px auto;\n    cursor: pointer !important;\n    z-index: 999;\n}\n.buttonArrow {\n    margin-left: 10px;\n    width: 36px;\n}\n.glow {\n    box-shadow: 0 0 30px #00ff00;\n}\n\n@media screen and (max-width: 992px) {\n    .journeyButton {\n        margin: 20px auto;\n        width: 49%;\n    }\n}\n@media screen and (max-width: 768px) {\n    .journeyButton {\n        margin: 15px auto;\n        width: 68%;\n        font-size: 20px;\n    }\n}\n@media screen and (max-width: 350px) {\n    .journeyButton {\n        padding: 8px;\n        font-size: 16px;\n        gap: 0px;\n    }\n    .buttonArrow {\n        width: 25px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"startMyJourney": `StartMyJourney_startMyJourney__3yIdb`,
	"journeyButton": `StartMyJourney_journeyButton__nSsWp`,
	"buttonArrow": `StartMyJourney_buttonArrow__8mtJs`,
	"glow": `StartMyJourney_glow__M9uyM`
};
export default ___CSS_LOADER_EXPORT___;
