import React, { useRef, useState, useEffect } from 'react';
import Pricing from '../.shared/Pricing';
import Header from '../.shared/header';
import Footer from '../.shared/footer';

import styles from './Programs.module.css';
import boderline from "../../assets/boderline.png"
import Semaglutide from "../../assets/Semaglutide.png"
import Tirzepatide from "../../assets/Tirzepatide.png"
import or from "../../assets/or.png"
import or1 from "../../assets/or-1.png"
import tick from "../../assets/tick350.png"
import footerline from '../../assets/boderline.png';
import circleRight from '../../assets/circle-right.svg';


const Maintenance = () => {
        
    const pricing = Pricing();
    const prices = pricing.getPrices();
    const isStart = window.location.pathname.includes('start');
    const [isVisible, setIsVisible] = useState(false);
    const elementRef = useRef(null);

    function handleStart() {
        window.location.href = '/checkout';
    }

    function handleProgram(e) {
        let selection = pricing.getPlan(e.currentTarget.dataset.id)
        localStorage.setItem('price_id', selection.price_id);
        window.location.href = '/checkout';
    }

    function handleSemaHover(e) {
        e.target.classList.add('animate__animated', 'animate__pulse', styles.glowGreen);
    }
    function handleSemaHoverEnd(e) {
        e.target.classList.remove('animate__animated', 'animate__pulse', styles.glowGreen);
    }
    function handleTirzHover(e) {
        e.target.classList.add('animate__animated', 'animate__pulse', styles.glowBlue);
    }
    function handleTirzHoverEnd(e) {
        e.target.classList.remove('animate__animated', 'animate__pulse', styles.glowBlue);
    }
    function handleJourneyHover(e) {
        e.target.classList.add('animate__animated', 'animate__pulse', styles.glowGreen);
    }
    function handleJourneyHoverEnd(e) {
        e.target.classList.remove('animate__animated', 'animate__pulse', styles.glowGreen);
    }

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            {
                threshold: 0.5,
            }
        );

        let currentElement = elementRef.current;
        if (currentElement) {
            observer.observe(currentElement);
        }
        return () => {
            if (currentElement) {
                observer.unobserve(currentElement);
            }
        };
    }, []);

    return (
        <>
            <Header />
            <div className={styles.programBanner}>
                <div className={styles.bannerText}>
                    {!isStart &&
                        <div className={styles.bannerSubtitle}>Safe, Effective, Proven Weight Loss</div>
                    }                    
                    <div className={styles.bannerTitle}>Maintenance Programs</div>
                    {isStart &&
                        <div className={styles.checkoutSelect}>First select your program</div>
                    }
                </div>
                <img className={styles.programDivider} src={boderline} alt='' />
            </div>

            <div className={styles.goToMaintenance}>
                <span>
                    These programs are designed for patients experienced with GLP-1s who have lost the weight and want to keep it off. 
                    If you are new to GLP-1s and are looking to lose weight, consider 
                    our <a href="/programs">Weight Loss Programs</a> instead.
                </span>
            </div>
            
            <div className={styles.programs}>
                <div className={styles.maintenanceProgram}>
                    <div className={styles.maintenancePlans}>
                        {prices.semaglutide.maintenance &&
                            <div id='Semaglutide' className={styles.maintenancePlan + ' animate__animated animate__slideInLeft'}>
                                <img className={styles.programImage} src={Semaglutide} alt='' />
                                <div className={styles.maintenancePlanBox}>
                                    <div className={styles.semaMaintenancePlan}>Semaglutide</div>
                                    <div className={styles.semaMaintenanceDescription}>{prices.semaglutide.maintenance.sixMonths?.dose }</div>
                                    <div className={styles.semaglutideMaintenancePlans}>
                                        {prices.semaglutide.maintenance.sixMonths &&
                                            <div className={styles.planSubscription} data-id={prices.semaglutide.maintenance.sixMonths.price_id} onClick={handleProgram}>
                                                <div className={styles.maintenancePlanPrice}>${prices.semaglutide.maintenance.sixMonths.startingAt}</div>
                                                <div className={styles.maintenancePlanName}>every 6 months</div>
                                                <div className={styles.maintenanceLetsGo + ' ' + styles.semaBackground} onMouseEnter={handleSemaHover} onMouseLeave={handleSemaHoverEnd}>Let's Go!<img className={styles.buttonArrow} src={circleRight} alt='Start' /></div>
                                            </div>
                                        }                                            
                                    </div>
                                </div>
                            </div>
                        }
                        {prices.tirzepatide.maintenance &&
                            <div id='Tirzepatide' className={styles.maintenancePlan + ' animate__animated animate__slideInRight'}>
                                <div className={styles.maintenancePlanBox}>
                                    <div className={styles.tirzMaintenancePlan}>Tirzepatide</div>
                                    <div className={styles.tirzMaintenanceDescription}>{prices.tirzepatide.maintenance.sixMonths?.dose }</div>
                                    <div className={styles.tirzepatideMaintenancePlans}>
                                        {prices.tirzepatide.maintenance.sixMonths &&
                                            <div className={styles.planSubscription} data-id={prices.tirzepatide.maintenance.sixMonths.price_id} onClick={handleProgram}>
                                                <div className={styles.maintenancePlanPrice}>${prices.tirzepatide.maintenance.sixMonths.startingAt}</div>
                                                <div className={styles.maintenancePlanName}>every 6 months</div>
                                                <div className={styles.maintenanceLetsGo + ' ' + styles.tirzBackground} onMouseEnter={handleTirzHover} onMouseLeave={handleTirzHoverEnd}>Let's Go!<img className={styles.buttonArrow} src={circleRight} alt='Start' /></div>
                                            </div>
                                        }                                            
                                    </div>
                                </div>
                                <img className={styles.programImage} src={Tirzepatide} alt='' />
                            </div>
                        }
                    </div>                        
                </div>
            </div>

            <img className={styles.programBorder} src={footerline} alt='wait' />
            
            <div className={styles.allPrograms}>
                <div className={styles.programsTitle}><span>All</span> Programs include:</div>
                <div className={styles.programsDescription}>
                    <div className={styles.programsList}>
                        <div><img className={styles.checkmark} src={tick} alt='' />Initial consultation with a clinician</div>
                        <div><img className={styles.checkmark} src={tick} alt='' />Assignment of a Patient Care Coordinator</div>
                        <div><img className={styles.checkmark} src={tick} alt='' />Prescription for a GLP-1 agonist</div>
                        <div><img className={styles.checkmark} src={tick} alt='' />Followup with a clinician every 30 days</div>
                        <div><img className={styles.checkmark} src={tick} alt='' />Access to your clinician between consultations</div>
                        <div><img className={styles.checkmark} src={tick} alt='' />Free shipping to your door</div>
                        <div><img className={styles.checkmark} src={tick} alt='' />Plans renew automatically</div>
                        <div><img className={styles.checkmark} src={tick} alt='' />Cancel at any time</div>
                        <div><img className={styles.checkmark} src={tick} alt='' />Money back guarantee if you do not qualify for a prescription</div>
                    </div>
                </div>
                <div onMouseEnter={handleJourneyHover} onMouseLeave={handleJourneyHoverEnd} onClick={handleStart} className={styles.journeyBtn2}>Start My Weight Loss Journey<img className={styles.journeyArrow} src={circleRight} alt='' /> </div>
            </div>
            <Footer />
        </>

    );
};

export default Maintenance;