import styles from './Articles.module.css';
import Header from '../.shared/header';
import Footer from '../.shared/footer';
import NYWeekly from '../../assets/NY-Weekly.png';
import CEOWeekly from '../../assets/CEO-Weekly.png';
import BodyNetwork from '../../assets/Body-Network.png';
import SingleCare from '../../assets/SingleCare.png';
import LAWire from '../../assets/la-wire.png';
import DailyMail from '../../assets/daily-mail.png';
import IBD from '../../assets/ibd-logo.svg';
import Valiant from '../../assets/valiant-ceo.png';
import HealthcareIT from '../../assets/healthcare-it-today.png';
import BritishBulletin from '../../assets/british-bulletin.jpeg';
import MedicalNewsToday from '../../assets/MedicalNewsToday.png';
import AuthorityMagazine from '../../assets/Authority-Magazine.png';
import boderline from "../../assets/boderline.png"

function Articles() {
    return (
        <>
            <Header />
            <div className={styles.ArticlesTitle}>Articles</div>
            <img className={styles.ArticlesBorder} src={boderline} alt='' />
            <div className={styles.Articles}>
                <div className={styles.CoveredArticle} onClick={() => window.open('https://medium.com/authority-magazine/paul-moss-and-antoni-adamrovich-of-tb2-health-49f6d473922c')}>
                    <img src={AuthorityMagazine} alt='Authority Magazine' />
                    Paul Moss and Antoni Adamrovich Of Tb2.Health On What You Need To Know Before You Consider Taking Weight Loss Drugs
                </div>
                <div className={styles.CoveredArticle} onClick={() => window.open('https://www.medicalnewstoday.com/articles/how-semaglutide-drugs-such-as-ozempic-can-help-with-long-term-weight-loss-and-heart-health')}>
                    <img src={MedicalNewsToday} alt='Medical News Today' />
                    Ozempic: How semaglutide drugs can help with long-term weight loss - Medical News Today
                </div>
                <div className={styles.CoveredArticle} onClick={() => window.open('https://www.dailymail.co.uk/health/article-13276757/deaths-linked-ozempic-weight-loss-drugs-analysis.html')}>
                    <img src={DailyMail} alt='Daily Mail' />
                    More than 100 US deaths linked to Ozempic and similar drugs
                </div>
                <div className={styles.CoveredArticle} onClick={() => window.open('https://nyweekly.com/health/how-a-new-weight-loss-system-is-changing-lives-including-the-companys-ceos/')}>
                    <img src={NYWeekly} alt='New York Weekly' />
                    How a New Weight-Loss System Is Changing Lives, Including The Company’s CEOs
                </div>
                <div className={styles.CoveredArticle} onClick={() => window.open('https://ceoweekly.com/meet-paul-moss-ceo-and-co-founder-of-tb2-health/')}>
                    <img src={CEOWeekly} alt='CEO Weekly' />
                    Meet Paul Moss, CEO and Co-founder of TB2.Health
                </div>
                <div className={styles.CoveredArticle} onClick={() => window.open('https://bodynetwork.com/nutrition-15-unhealthy-foods-to-avoid-expert-advice/')}>
                    <img src={BodyNetwork} alt='Body Network' />
                    15 Foods You Should Always Avoid, According to Experts
                </div>
                <div className={styles.CoveredArticle} onClick={() => window.open('https://bodynetwork.com/weight-loss-maintenance-tips-after-ozempic/')}>
                    <img src={BodyNetwork} alt='Body Network' />
                    12 Tips to Maintain Weight Loss After Using Ozempic and GLP-1 Medications
                </div>
                <div className={styles.CoveredArticle} onClick={() => window.open('https://www.singlecare.com/blog/ozempic-diet/')}>
                    <img src={SingleCare} alt='Single Care' />
                    Ozempic diet: What to eat for weight loss
                </div>
                <div className={styles.CoveredArticle} onClick={() => window.open('https://lawire.com/semaglutides-changed-my-life-the-tb2-health-founders-story-on-creating-a-health-centric-weight-loss-solution/')}>
                    <img src={LAWire} alt='LA Wire' />
                    The TB2.Health Founders’ Story on Health-Centric Weight Loss
                </div>
                <div className={styles.CoveredArticle} onClick={() => window.open('https://www.investors.com/news/technology/dexcom-dxcm-stock-abbott-abt-continuous-glucose-monitor-cgm/')}>
                    <img src={IBD} alt='Investors Business Daily' />
                    Why Wall Street Got Even More Excited About The Glucose Monitor — And How Dexcom, Abbott Labs Stand To Benefit
                </div>
                <div className={styles.CoveredArticle} onClick={() => window.open('https://valiantceo.com/paul-moss-tb2-health/')}>
                    <img src={Valiant} alt='Valiant CEO' />
                    From Overweight to Overcoming: Paul Moss’s Journey to Health and Entrepreneurship
                </div>
                <div className={styles.CoveredArticle} onClick={() => window.open('https://www.healthcareittoday.com/2024/05/01/lean-digital-behavior-change-for-weight-and-obesity/')}>
                    <img src={HealthcareIT} alt='Healthcare IT Today' />
                    Lean Digital: Behavior Change for Weight and Obesity
                </div>
                <div className={styles.CoveredArticle} onClick={() => window.open('https://britishbulletin.com/exclusivemore-than-100-us-deaths-linked-to-ozempic-and-similar-weight-loss-drugs-including-28-year-old-who-died-from-intestinal-mass-and-a-pregnant-woman-our-analysis-shows/')}>
                    <img src={BritishBulletin} alt='British Bulletin' />
                    EXCLUSIVEMore than 100 US deaths linked to Ozempic and similar weight loss drugs – including 28-year-old who died from ‘intestinal mass’ and a pregnant woman, our analysis shows
                </div>

            </div>

            <Footer />
        </>

    );
};

export default Articles;