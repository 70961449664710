// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Welcome_container__PoQL9 {
    text-align: left;
    margin: 0 auto;
    margin-top: 6em;
    margin-bottom: 4em;
    font-family: 'Aktiv Grotesk', sans-serif;
    font-size: 20px;
    width: 90%;
}
.Welcome_container__PoQL9 li {
    margin-bottom: 10px;
}
.Welcome_container__PoQL9 h2 {
    font-family: 'Cocon Bold', sans-serif;
    font-size: 2.5em;
    margin-bottom: 1em;
    text-align: center;
}
.Welcome_divider__6RsID {
    width: 90%;
    margin: 0 5%;
    margin-bottom: 2em;
}
.Welcome_videoInstructions__muO5b {
    position: relative;
    text-align: center;
}
@media screen and (max-width: 768px) {
    .Welcome_container__PoQL9 {
        font-size: 18px;
    }
    .Welcome_container__PoQL9 ul {
        padding: 0;
        margin: 1em 0;
    }
    .Welcome_container__PoQL9 li {
        margin-bottom: 10px;
        list-style-type: none;
        padding-left: 0;
    }
    .Welcome_videoInstructions__muO5b iframe {
        width: 100%;
        height: 300px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Welcome/Welcome.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,wCAAwC;IACxC,eAAe;IACf,UAAU;AACd;AACA;IACI,mBAAmB;AACvB;AACA;IACI,qCAAqC;IACrC,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;AACtB;AACA;IACI,UAAU;IACV,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,kBAAkB;AACtB;AACA;IACI;QACI,eAAe;IACnB;IACA;QACI,UAAU;QACV,aAAa;IACjB;IACA;QACI,mBAAmB;QACnB,qBAAqB;QACrB,eAAe;IACnB;IACA;QACI,WAAW;QACX,aAAa;IACjB;AACJ","sourcesContent":[".container {\n    text-align: left;\n    margin: 0 auto;\n    margin-top: 6em;\n    margin-bottom: 4em;\n    font-family: 'Aktiv Grotesk', sans-serif;\n    font-size: 20px;\n    width: 90%;\n}\n.container li {\n    margin-bottom: 10px;\n}\n.container h2 {\n    font-family: 'Cocon Bold', sans-serif;\n    font-size: 2.5em;\n    margin-bottom: 1em;\n    text-align: center;\n}\n.divider {\n    width: 90%;\n    margin: 0 5%;\n    margin-bottom: 2em;\n}\n.videoInstructions {\n    position: relative;\n    text-align: center;\n}\n@media screen and (max-width: 768px) {\n    .container {\n        font-size: 18px;\n    }\n    .container ul {\n        padding: 0;\n        margin: 1em 0;\n    }\n    .container li {\n        margin-bottom: 10px;\n        list-style-type: none;\n        padding-left: 0;\n    }\n    .videoInstructions iframe {\n        width: 100%;\n        height: 300px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Welcome_container__PoQL9`,
	"divider": `Welcome_divider__6RsID`,
	"videoInstructions": `Welcome_videoInstructions__muO5b`
};
export default ___CSS_LOADER_EXPORT___;
