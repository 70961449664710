import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { handleRedirect } from '../../utils/redirectUtils';

import styles from './header.module.css';

import logo from '../../assets/logo.png';
import msg from '../../assets/msg.png';
import menu from '../../assets/menu.png';
import phone from '../../assets/phone.png';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import HomeIcon from '@mui/icons-material/Home';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import BookIcon from '@mui/icons-material/Book';
import LoginIcon from '@mui/icons-material/Login';



function Header() {
    const [open, setOpen] = React.useState(false);
    const [isScrolling, setIsScrolling] = useState(false);
    const redirectUrl = localStorage.getItem('redirectUrl');

    const [isVisible, setIsVisible] = useState(false);
    const elementRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            {
                threshold: 0.5,
            }
        );

        let currentElement = elementRef.current;
        if (currentElement) {
            observer.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                observer.unobserve(currentElement);
            }
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolling(window.scrollY > 0);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    function handleHover(e) {
        e.target.classList.add('animate__animated', 'animate__pulse', styles.glow);
    }
    function handleHoverEnd(e) {
        e.target.classList.remove('animate__animated', 'animate__pulse', styles.glow);
    }

    const DrawerList = (
        <Box sx={{ width: 275 }} role="presentation" onClick={toggleDrawer(false)}>
            <List>
                <ListItem disablePadding>
                    <ListItemButton to='/'>
                        <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Home" />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={handleRedirect}>
                        <ListItemIcon>
                            <LocalLibraryIcon />
                        </ListItemIcon>
                        <ListItemText primary="Weight Loss Programs" />
                    </ListItemButton>
                </ListItem>
                {/* <ListItem disablePadding>
                    <ListItemButton to='/maintenance'>
                        <ListItemIcon>
                            <LocalLibraryIcon />
                        </ListItemIcon>
                        <ListItemText primary="Maintenance Programs" />
                    </ListItemButton>
                </ListItem> */}
                <ListItem disablePadding>
                    <ListItemButton to='/knowledge-center'>
                        <ListItemIcon>
                            <VerifiedUserIcon />
                        </ListItemIcon>
                        <ListItemText primary="Knowledge Center" />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton to='/success-stories'>
                        <ListItemIcon>
                            <FavoriteIcon />
                        </ListItemIcon>
                        <ListItemText primary="Success Stories" />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton to='/articles'>
                        <ListItemIcon>
                            <ImportContactsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Articles" />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton to='/blog'>
                        <ListItemIcon>
                            <BookIcon />
                        </ListItemIcon>
                        <ListItemText primary="Blog" />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton to='/login'>
                        <ListItemIcon>
                            <LoginIcon />
                        </ListItemIcon>
                        <ListItemText primary="Login" />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    return (
        <div className={isScrolling ? styles.scrollingHeader : styles.staticHeader}>
            <div>
                <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                    {DrawerList}
                </Drawer>
            </div>
            <div className={styles.mainSection}>
                <div>
                    <Link to="/"><img className={styles.logo} src={logo} alt='TB2' /></Link>
                </div>
                <div>
                    <div className={styles.getStartedButton} onMouseEnter={handleHover} onMouseLeave={handleHoverEnd} onClick={handleRedirect}>Get Started</div>
                    <div className={styles.buttonsHeader}>
                        <div className={styles.numberText}>
                            <a href="tel:18445703201">
                                {window.innerWidth > 638 && '844.570.3201'}
                                {window.innerWidth <= 638 && <img src={phone} alt='phone' />}
                            </a>
                        </div>
                        <a href="mailto:social@tb2.health"><img className={styles.messageIcon} src={msg} alt='social@tb2.health' /></a>
                        <img className={styles.messageIcon} style={{ cursor: "pointer" }} onClick={toggleDrawer(true)} src={menu} alt='menu' />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;
