export const handleRedirect = async () => {
    const redirectUrl = localStorage.getItem('redirectUrl') ?? 'https://tb2.mystarthealth.com/results?utm_source=';

    try {
        const response = await fetch(process.env.REACT_APP_TB2_API_URL + '/events/redirect', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                source: localStorage.getItem('source'),
                subdomain: localStorage.getItem('subdomain'),
                redirectUrl: redirectUrl,
            }),
        });

        const data = await response.json();
        console.log(data);
    } catch (error) {
        console.error('Error capturing redirect:', error);
    } finally {
        window.location.href = redirectUrl;
    }
};