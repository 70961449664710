import { Link } from 'react-router-dom';
import Header from '../.shared/header';
import Footer from '../.shared/footer';

import styles from './Welcome.module.css';
import boderline from "../../assets/boderline.png"

function Welcome() {

    return (
        <>
            <Header />
            <div className={styles.container}>
                <h2>Welcome</h2>
                <img className={styles.divider} src={boderline} alt='' />
                <p>Dear Patient,</p>
                <p>We want to congratulate you on your decision to start GLP-1 medication as part of your treatment plan. It's a significant step towards managing your health, and we're here to support you every step of the way.</p>
                <p>Starting a new medication can be an adjustment, so we want to provide you with some important considerations to help you reduce any potential side effects and ensure you have a smooth experience with your GLP-1 therapy:
                    <ul>
                        <li><b>Dosage and Timing:</b> Please take your medication exactly as prescribed by your healthcare provider. Consistency in dosage and timing is crucial for the effectiveness of GLP-1 medications.</li>
                        <li><b>Diet and Exercise:</b> Continue to follow a healthy diet and maintain regular physical activity as recommended by your healthcare provider. These lifestyle factors play an essential role in managing your condition alongside your medication.</li>
                        <li>
                            <b>Injection Technique:</b> If your GLP-1 medication is in the form of a subcutaneous injection, proper technique is vital for comfort and effectiveness. Be sure to follow your healthcare provider's instructions carefully. If you have any questions or concerns about administering the injections, don't hesitate to reach out to your healthcare team.
                            <p className={styles.videoInstructions}>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/x3vN_TxpIfA" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; fullscreen; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </p>
                        </li>
                        <li><b>Side Effects:</b> Like any medication, GLP-1 medications may have side effects. These can vary from person to person. Common side effects may include nausea or stomach upset. If you experience any side effects, please inform your healthcare provider promptly. They can provide guidance on how to manage them effectively.</li>
                        <li><b>Resources and Support:</b> We want to make sure you have access to all the information and resources you need for a successful journey with your GLP-1 medication. Visit our <Link to='/knowledge-center'>Knowledge Center</Link> for additional resources, including tips for managing side effects.</li>
                    </ul>
                </p>
                <p>Remember, your healthcare team is here to support you throughout your treatment. If you have any questions or concerns at any point, don't hesitate to reach out to your healthcare provider or our office at support@tb2.health.</p>
                <p>We applaud your commitment to your health and your decision to start GLP-1 therapy. With the right information and support, we believe you can achieve your health goals.</p>
                <p>Thank you for entrusting us with your care, and we look forward to assisting you on your journey towards improved health.</p>
                <p>Warm regards,<br/>Antoni Adamrovich, MSN, BA, APRN, FNP-C<br/>Chief Medical Officer<br/>TB2.health</p>
            </div>
            <Footer />
        </>
    );
}

export default Welcome;