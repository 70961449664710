import React from 'react';
import styles from './KnowledgeCenter.module.css';

function ResearchCenter() {

    return (
        <>
            <div className={styles.researchSection}>
                <h3 className={styles.researchTitle}>Research Center</h3>
                <p>We are committed to providing the most up-to-date information on the latest research and clinical trials for GLP-1s and other medications for weight loss and diabetes. We will be updating this page regularly with new information as it becomes available.</p>
                <div>
                    <h4 className={styles.researchGetStarted}>Getting Started</h4>
                    <p className={styles.researchPaper}>
                        <a href='https://elsevier.health/en-US/preview/glucagon-like-peptide-1-glp-1-receptor-agonists' target='_blank' rel="noreferrer">GLP Overview</a><br />
                        Good starting article on drug class overview of GLP's with a chart including medication trials.
                    </p>
                    <p className={styles.researchPaper}>
                        <a href='https://www.fool.com/investing/2023/08/05/the-quirky-history-of-weight-loss-drugs/' target='_blank' rel="noreferrer">The Quirky History of Weight Loss Drugs</a><br />
                        Fun article introducing people to the wild and wacky history of GLP medications.
                    </p>
                    <p className={styles.researchPaper}>
                        <a href='https://diabetes.medicinematters.com/tirzepatide/type-2-diabetes/a-quick-guide-to-the-surpass-and-surmount-trials/18478154' target='_blank' rel="noreferrer">A quick guide to the SURPASS and SURMOUNT trials</a><br />
                        Good guide to all the GLP/GIP trials.
                    </p>
                    <p className={styles.researchPaper}>
                        <a href='https://research.tb2.health/articles/GIP%20and%20GLP-1%20similarity%20and%20differences.pdf' target='_blank' rel="noreferrer">GIP and GLP-1, the two incretin hormones: Similarities and differences</a><br />
                        Gastric inhibitory polypeptide (GIP) and glucagon-like peptide-1 (GLP-1) are the two
                        primary incretin hormones secreted from the intestine on ingestion of glucose or nutrients to
                        stimulate insulin secretion...<a href='https://research.tb2.health/articles/GIP%20and%20GLP-1%20similarity%20and%20differences.pdf' target='_blank' rel="noreferrer">Read more</a>
                    </p>
                    <p className={styles.researchPaper}>
                        <a href='https://research.tb2.health/articles/Role%20of%20GIP%20GLP%20in%20glycemic%20control%20from%20pathophysiology%20to%20treatment.pdf' target='_blank' rel="noreferrer">Role of GIP GLP in glycemic control from pathophysiology to treatment</a><br />
                        Glucagon-like peptide-1 (GLP-1) and glucose-dependent insulinotropic polypeptide (GIP)
                        are two gut hormones, defined incretins, responsible for the amplification of insulin secretion after
                        oral glucose intake. Unlike GLP-1, GIP has...<a href='https://research.tb2.health/articles/Role%20of%20GIP%20GLP%20in%20glycemic%20control%20from%20pathophysiology%20to%20treatment.pdf' target='_blank' rel="noreferrer">Read more</a>
                    </p>
                    <div className={styles.semaSection}>
                        <div className={styles.header}>
                            Semaglutide
                        </div>
                        <p className={styles.researchPaper}>
                            <a href='https://research.tb2.health/articles/Exploring%20the%20wider%20benefits%20of%20semaglutide%20treatment%20in%20obesity%20%20insight%20from%20the%20STEP%20program.pdf' target='_blank' rel="noreferrer">Exploring the wider benefits of semaglutide treatment in obesity: insight from the STEP program</a><br />
                            Obesity negatively impacts patients' health-related quality of life (QOL) and is associated
                            with a range of complications such as type 2 diabetes (T2D), cardiovascular disease, and
                            sleep apnea, alongside decreased physical function, mobility, and control of eating. The
                            Semaglutide Treatment Effect in People with obesity (STEP) trials compared... <a href='https://research.tb2.health/articles/Exploring%20the%20wider%20benefits%20of%20semaglutide%20treatment%20in%20obesity%20%20insight%20from%20the%20STEP%20program.pdf' target='_blank' rel="noreferrer">Read more</a>
                        </p>
                        <p className={styles.researchPaper}>
                            <a href='https://research.tb2.health/articles/Once%20weekly%20semaglutide%20in%20adults%20with%20overweight%20or%20obesity.pdf' target='_blank' rel="noreferrer">Once-Weekly Semaglutide in Adults with Overweight or Obesity</a><br />
                            Obesity is a global health challenge with few pharmacologic options. Whether adults with obesity
                            can achieve weight loss with once-weekly semaglutide... <a href='https://research.tb2.health/articles/Once%20weekly%20semaglutide%20in%20adults%20with%20overweight%20or%20obesity.pdf' target='_blank' rel="noreferrer">Read more</a>
                        </p>
                        <p className={styles.researchPaper}>
                            <a href='https://research.tb2.health/articles/Semaglutide%202%204%20mg%20wk%20for%20weight%20loss%20in%20patients%20with%20severe%20obesity%20and%20with%20or%20without%20a%20history%20of%20bariatric%20surgery.pdf' target='_blank' rel="noreferrer">Semaglutide 2.4 mg/wk for weight loss in patients with severe obesity and with or without a history of bariatric surgery</a><br />
                            This retrospective cohort study aimed to assess the effectiveness of semaglutide 2.4 mg in patients
                            with severe obesity (BMI ≥ 40 kg/m2) who had previously undergone bariatric surgery... <a href='https://research.tb2.health/articles/Semaglutide%202%204%20mg%20wk%20for%20weight%20loss%20in%20patients%20with%20severe%20obesity%20and%20with%20or%20without%20a%20history%20of%20bariatric%20surgery.pdf' target='_blank' rel="noreferrer">Read more</a>
                        </p>
                        <p className={styles.researchPaper}>
                            <a href='https://tb2research.blob.core.windows.net/articles/Efficacy%20and%20safety%20of%20semaglutide%202%204%20mg%20for%20weight%20loss%20in%20overweight%20or%20obese.pdf' target='_blank' rel="noreferrer">Efficacy and safety of semaglutide 2.4 mg for weight loss in overweight or obese adults without diabetes: An updated systematic review and meta-analysis including the 2-year STEP 5 trial</a><br />
                            Aim: To explore the safety and efficacy of subcutaneous semaglutide 2.4 mg, administered once a week in non-diabetic overweight or obese individuals.
                            Methods: A thorough search was performed of various databases including PubMed,
                            Embase, the Cochrane Library, Web of Science, clinicaltrials.gov, CNKI and Wanfang
                            from their inception up to April 11, 2023... <a href='https://tb2research.blob.core.windows.net/articles/Efficacy%20and%20safety%20of%20semaglutide%202%204%20mg%20for%20weight%20loss%20in%20overweight%20or%20obese.pdf' target='_blank' rel="noreferrer">Read more</a>
                        </p>
                    </div>
                    <div className={styles.tirzSection}>
                        <div className={styles.header}>
                            Tirzepatide
                        </div>
                        <p className={styles.researchPaper}>
                            <a href='https://research.tb2.health/articles/Tirzepatide%20New%20Era%20of%20Dual%20Targeted%20Tx%20for%20DM%20and%20Obesity.pdf' target='_blank' rel="noreferrer">Tirzepatide New Era of Dual Targeted Tx for DM and Obesity</a><br />
                            The prevalence of obesity and diabetes is an increasing global problem, especially in developed
                            countries, and is referred to as the twin epidemics. As such, advanced treatment approaches are needed.
                            Tirzepatide, known as a 'twincretin', is a 'first-in-class' and the only dual glucagon-like peptide-1
                            (GLP-1) and glucose-dependent insulinotropic peptide (GIP) receptor agonist, that can significantly
                            reduce glycemic levels and improve insulin sensitivity, as well as reducing body weight by more than
                            20% and improving lipid metabolism... <a href='https://research.tb2.health/articles/Tirzepatide%20New%20Era%20of%20Dual%20Targeted%20Tx%20for%20DM%20and%20Obesity.pdf' target='_blank' rel="noreferrer">Read more</a>
                        </p>
                        <p className={styles.researchPaper}>
                            <a href='https://research.tb2.health/articles/Tirzepatide%20Once%20Weekly%20for%20the%20Treatment%20of%20Obesity.pdf' target='_blank' rel="noreferrer">Tirzepatide Once Weekly for the Treatment of Obesity</a><br />
                            Obesity is a chronic disease that results in substantial global morbidity and mortality. The efficacy
                            and safety of tirzepatide, a novel glucose-dependent insulinotropic polypeptide and glucagon-like
                            peptide-1 receptor agonist, in people with obesity... <a href='https://research.tb2.health/articles/Tirzepatide%20Once%20Weekly%20for%20the%20Treatment%20of%20Obesity.pdf' target='_blank' rel="noreferrer">Read more</a>
                        </p>
                        <p className={styles.researchPaper}>
                            <a href='https://research.tb2.health/articles/Tirzepatide%20versus%20Semaglutide%20Once%20Weekly%20in%20Patients%20with%20DMII.pdf' target='_blank' rel="noreferrer">Tirzepatide versus Semaglutide Once Weekly in Patients with DMII</a><br />
                            Tirzepatide is a dual glucose-dependent insulinotropic polypeptide and glucagonlike peptide-1 (GLP-1)
                            receptor agonist that is under development for the treatment of type 2 diabetes. The efficacy and
                            safety of once-weekly tirzepatide as compared with semaglutide... <a href='https://research.tb2.health/articles/Tirzepatide%20versus%20Semaglutide%20Once%20Weekly%20in%20Patients%20with%20DMII.pdf' target='_blank' rel="noreferrer">Read more</a>
                        </p>
                    </div>
                    <div className={styles.semaSection}>
                        <div className={styles.header}>
                            Side Effects
                        </div>
                        <p className={styles.researchPaper}>
                            <a href='https://research.tb2.health/articles/A%20review%20or%20the%20effects%20of%20GLP-1RA%20and%20SGLT2%20on%20lean%20body%20mass%20in%20humans.pdf' target='_blank' rel="noreferrer">A review or the effects of GLP-1RA and SGLT2 on lean body mass in humans</a><br />
                            Weight loss is an important goal in the management of several chronic conditions, including type 2 diabetes
                            mellitus, and pharmacological therapies that aid weight loss are appealing. Glucagon-like peptide-1 receptor
                            agonists (GLP-1RAs) and sodium-glucose cotransporter 2 inhibitors (SGLT2is) are novel glucose-lowering
                            therapies that have been shown to induce clinically significant reductions in body weight... <a href='https://research.tb2.health/articles/A%20review%20or%20the%20effects%20of%20GLP-1RA%20and%20SGLT2%20on%20lean%20body%20mass%20in%20humans.pdf' target='_blank' rel="noreferrer">Read more</a>
                        </p>
                        <p className={styles.researchPaper}>
                            <a href='https://research.tb2.health/articles/Managing%20the%20gastrointestinal%20side%20effects%20of%20GLP%201%20receptor%20agonists%20in%20obesity%20recommendations%20for%20clinical%20practice.pdf' target='_blank' rel="noreferrer">Managing the gastrointestinal side effects of GLP 1 receptor agonists in obesity recommendations for clinical practice</a><br />
                            Clinical guidelines for obesity typically recommend considering pharmacotherapy, such as treatment with
                            a GLP-1RA, for achievement of weight loss and for weight loss maintenance... <a href='https://research.tb2.health/articles/Managing%20the%20gastrointestinal%20side%20effects%20of%20GLP%201%20receptor%20agonists%20in%20obesity%20recommendations%20for%20clinical%20practice.pdf' target='_blank' rel="noreferrer">Read more</a>
                        </p>
                        <p className={styles.researchPaper}>
                            <a href='https://research.tb2.health/articles/Glucagon-like%20peptide-1%20receptor%20agonists%20and%20risk%20of%20thyroid%20cancer.pdf' target='_blank' rel="noreferrer">Glucagon-like peptide-1 receptor agonists and risk of thyroid cancer</a><br />
                            Glucagon-like peptide-1 receptor agonists (GLP-1RAs) are widely used as a treatment for type 2 diabetes,
                            due to their ability to improve glucose control without increasing hypoglycaemic risk. They have also
                            been shown to reduce the incidence of cardiovascular events... <a href='https://research.tb2.health/articles/Glucagon-like%20peptide-1%20receptor%20agonists%20and%20risk%20of%20thyroid%20cancer.pdf' target='_blank' rel="noreferrer">Read more</a>
                        </p>
                        <p className={styles.researchPaper}>
                            <a href='https://research.tb2.health/articles/Gastrointestinal%20adverse%20events%20and%20weight%20reduction%20in%20people%20with%20type%202%20diabetes%20treated%20with%20tirzepatide.pdf' target='_blank' rel="noreferrer">Gastrointestinal adverse events and weight reduction in people with type 2 diabetes treated with tirzepatide</a><br />
                            Globally, 60% of people with type 2 diabetes (T2D) have obesity (defined as a body mass index ≥30 kg/m<sup>2</sup>).
                            People with T2D and obesity are at a higher risk of developing cardiometabolic complications compared with
                            people with T2D alone. Thus, current guidelines recommend weight management as one of the priorities in the
                            treatment of T2D as part of a multifactorial approach... <a href='https://research.tb2.health/articles/Gastrointestinal%20adverse%20events%20and%20weight%20reduction%20in%20people%20with%20type%202%20diabetes%20treated%20with%20tirzepatide.pdf' target='_blank' rel="noreferrer">Read more</a>
                        </p>
                    </div>
                    <div className={styles.tirzSection}>
                        <div className={styles.header}>
                            Obesity
                        </div>
                        <p className={styles.researchPaper}>
                            <a href='https://tb2research.blob.core.windows.net/articles/Global%20Prevalance%20of%20Overweight%20and%20Obesity%20in%20Children%20and%20Adolescents.pdf' target='_blank' rel="noreferrer">Global Prevalance of Overweight and Obesity in Children and Adolescents</a><br />
                            Overweight and obesity in childhood and adolescence is a global health issue
                            associated with adverse outcomes throughout the life course.... <a href='https://tb2research.blob.core.windows.net/articles/Global%20Prevalance%20of%20Overweight%20and%20Obesity%20in%20Children%20and%20Adolescents.pdf' target='_blank' rel="noreferrer">Read more</a>
                        </p>
                        <p className={styles.researchPaper}>
                            <a href='https://research.tb2.health/articles/Weight%20regain%20and%20cardiometabolic%20effects%20after%20withdrawal%20of%20semaglutide.%20The%20STEP%201%20trial%20extension.pdf' target='_blank' rel="noreferrer">Weight regain and cardiometabolic effects after withdrawal of semaglutide. The STEP 1 trial extension</a><br />
                            Obesity is a highly prevalent, complex, chronic disease associated with cardiometabolic complications,
                            including type 2 diabetes, hypertension, dyslipidaemia and cardiovascular disease. Obesity also
                            leads to a wide range of other health problems, is associated with substantial socioeconomic burden
                            and was estimated to cause 5 million deaths globally in 2019... <a href='https://research.tb2.health/articles/Weight%20regain%20and%20cardiometabolic%20effects%20after%20withdrawal%20of%20semaglutide.%20The%20STEP%201%20trial%20extension.pdf' target='_blank' rel="noreferrer">Read more</a>
                        </p>
                        <p className={styles.researchPaper}>
                            <a href='https://research.tb2.health/articles/Obesity%20Global%20Epidemiology%20and%20Pathogenesis.pdf' target='_blank' rel="noreferrer">Obesity Global Epidemiology and Pathogenesis</a><br />
                            The prevalence of obesity has increased worldwide in the past ~50 years, reaching
                            pandemic levels. Obesity represents a major health challenge because it substantially increases
                            the risk of diseases such as... <a href='https://research.tb2.health/articles/Obesity%20Global%20Epidemiology%20and%20Pathogenesis.pdf' target='_blank' rel="noreferrer">Read more</a>
                        </p>
                        <p className={styles.researchPaper}>
                            <a href='https://research.tb2.health/articles/Use%20and%20continuity%20of%20weight-modifying%20medications%20among%20adults%20with%20diabetes%20and%20overweight.pdf' target='_blank' rel="noreferrer">Use and continuity of weight-modifying medications among adults with diabetes and overweight</a><br />
                            Trends in use and continuity of use of diabetes-specific and non-diabetes
                            weight-reducing (WR), weight-inducing (WI), and weight-neutral (WN) medications
                            were examined among US adults with diabetes and overweight/obesity... <a href='https://research.tb2.health/articles/Use%20and%20continuity%20of%20weight-modifying%20medications%20among%20adults%20with%20diabetes%20and%20overweight.pdf' target='_blank' rel="noreferrer">Read more</a>
                        </p>
                        <p className={styles.researchPaper}>
                            <a href='https://research.tb2.health/articles/Health%20Effects%20of%20Overweight%20and%20Obesity%20in%20195%20Countries%20over%2025%20Years.pdf' target='_blank' rel="noreferrer">Health Effects of Overweight and Obesity in 195 Countries over 25 Years</a><br />
                            Although the rising pandemic of obesity has received major attention in many
                            countries, the effects of this attention on trends and the disease burden of obesity
                            remain uncertain... <a href='https://research.tb2.health/articles/Health%20Effects%20of%20Overweight%20and%20Obesity%20in%20195%20Countries%20over%2025%20Years.pdf' target='_blank' rel="noreferrer">Read more</a>
                        </p>
                        <p className={styles.researchPaper}>
                            <a href="https://news.mit.edu/2023/engineers-develop-vibrating-ingestible-capsule-1222" target='_blank' rel="noreferrer">Engineers develop vibrating ingestible capsule to treat chronic diseases</a><br />
                            MIT engineers have designed an ingestible, Jell-O-like pill that, upon reaching the stomach, quickly swells to the size of a soft, squishy ping-pong ball big enough to stay in the stomach for an extended period of time. The inflatable pill could be used to monitor conditions in the stomach, or to deliver large drug doses or place an indwelling device in the stomach, the researchers say... <a href="https://news.mit.edu/2023/engineers-develop-vibrating-ingestible-capsule-1222" target='_blank' rel="noreferrer">Read more</a>
                        </p>
                    </div>
                    <div className={styles.semaSection}>
                        <div className={styles.header}>
                            Other Health Issues
                        </div>
                        <p className={styles.researchPaper}>
                            <a href='https://research.tb2.health/articles/Safety%20of%20GLP1RA%20and%20other%20second%20line%20antidiabetics%20in%20early%20pregnancy.pdf' target='_blank' rel="noreferrer">Safety of GLP1RA and other second line antidiabetics in early pregnancy</a><br />
                            Type 2 diabetes (T2D) is an increasingly common condition in female individuals of reproductive age
                            which has resulted in increased use of antidiabetic medication (ADM) during pregnancy... <a href='https://research.tb2.health/articles/Safety%20of%20GLP1RA%20and%20other%20second%20line%20antidiabetics%20in%20early%20pregnancy.pdf' target='_blank' rel="noreferrer">Read more</a>
                        </p>
                        <p className={styles.researchPaper}>
                            <a href='https://research.tb2.health/articles/Effects%20of%20GLP1RAs%20on%20pregnancy%20rate%20and%20menstrual%20cyclicity%20in%20women%20with%20PCOS.pdf' target='_blank' rel="noreferrer">Effects of GLP1RAs on pregnancy rate and menstrual cyclicity in women with PCOS</a><br />
                            This study was aimed to assess the efectiveness of Glucagon-like peptide 1 receptor agonists on pregnancy
                            rate, menses, anthropometric and hormonal parameters in PCOS patients... <a href='https://research.tb2.health/articles/Effects%20of%20GLP1RAs%20on%20pregnancy%20rate%20and%20menstrual%20cyclicity%20in%20women%20with%20PCOS.pdf' target='_blank' rel="noreferrer">Read more</a>
                        </p>
                        <p className={styles.researchPaper}>
                            <a href='https://research.tb2.health/articles/Semaglutide%20in%20patients%20with%20obesity%20and%20heart%20failure%20across%20mildly%20reduced%20or%20preserved%20EF.pdf' target='_blank' rel="noreferrer">Semaglutide in patients with obesity and heart failure across mildly reduced or preserved EF</a><br />
                            In this prespecified analysis, the authors assessed the effects of semaglutide across the baseline LVEF
                            strata in patients with the obesity phenotype of HF with preserved ejection fraction (HFpEF) in the
                            STEP-HFpEF (Semaglutide Treatment Effect in People with obesity and HFpEF) trial... <a href='https://research.tb2.health/articles/Semaglutide%20in%20patients%20with%20obesity%20and%20heart%20failure%20across%20mildly%20reduced%20or%20preserved%20EF.pdf' target='_blank' rel="noreferrer">Read more</a>
                        </p>
                        <p className={styles.researchPaper}>
                            <a href='https://research.tb2.health/articles/GLP%20analogue%20semaglutide%20reduces%20alcohol%20drinking%20and%20modulate%20central%20GABA%20neurotransmission.pdf' target='_blank' rel="noreferrer">GLP analogue semaglutide reduces alcohol drinking and modulate central GABA neurotransmission</a><br />
                            Growing evidence indicates that the glucagon-like peptide-1 (GLP-1) system is involved in the
                            neurobiology of addictive behaviors, and GLP-1 analogues may be used for the treatment of alcohol
                            use disorder (AUD)... <a href='https://research.tb2.health/articles/GLP%20analogue%20semaglutide%20reduces%20alcohol%20drinking%20and%20modulate%20central%20GABA%20neurotransmission.pdf' target='_blank' rel="noreferrer">Read more</a>
                        </p>
                        <p className={styles.researchPaper}>
                            <a href='https://research.tb2.health/articles/The%20role%20of%20GLP1%20in%20addictive%20disorders.pdf' target='_blank' rel="noreferrer">The role of GLP1 in addictive disorders</a><br />
                            Drug, alcohol and tobacco use disorders are a global burden affecting millions of people. Despite
                            decades of research, treatment options are sparse or missing, and relapse rates are high. Glucagon-like
                            peptide 1 (GLP-1) is released in the small intestine, promotes blood glucose homeostasis, slows gastric
                            emptying and reduces appetite. GLP-1 receptor agonists approved for treating Type 2 diabetes mellitus and
                            obesity have received attention as a potential anti-addiction treatment... <a href='https://research.tb2.health/articles/The%20role%20of%20GLP1%20in%20addictive%20disorders.pdf' target='_blank' rel="noreferrer">Read more</a>
                        </p>
                    </div>
                    <div className={styles.tirzSection}>
                        <div className={styles.header}>
                            Food
                        </div>
                        <p className={styles.researchPaper}>
                            <a href='https://research.tb2.health/articles/Nutritional%20modulation%20of%20endogenous%20GLP-1%20secretion.pdf' target='_blank' rel="noreferrer">Nutritional modulation of endogenous GLP-1 secretion</a><br />
                            The positive influences of glucagon-like peptide-1 (GLP-1) on blood glucose homeostasis, appetite
                            sensations, and food intake provide a strong rationale for its therapeutic potential in the
                            nutritional management of obesity and type 2 diabetes... <a href='https://research.tb2.health/articles/Nutritional%20modulation%20of%20endogenous%20GLP-1%20secretion.pdf' target='_blank' rel="noreferrer">Read more</a>
                        </p>
                        <p className={styles.researchPaper}>
                            <a href='https://research.tb2.health/articles/Effect%20of%20food%20ingredients%20on%20glucagon-like%20peptide-1%20secretion%20in%20STC-1%20and%20HuTu-80.pdf' target='_blank' rel="noreferrer">Effect of food ingredients on glucagon-like peptide-1 secretion in STC-1 and HuTu-80</a><br />
                            This study aims at identifying food ingredients that have potential to enhance satiety and therefore potentially
                            can be used for preventing and treating obesity... <a href='https://research.tb2.health/articles/Effect%20of%20food%20ingredients%20on%20glucagon-like%20peptide-1%20secretion%20in%20STC-1%20and%20HuTu-80.pdf' target='_blank' rel="noreferrer">Read more</a>
                        </p>
                    </div>
                </div>

            </div>
        </>

    );
};

export default ResearchCenter;