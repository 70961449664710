import Header from '../.shared/header';
import Footer from '../.shared/footer';

import styles from './Success.module.css';
import boderline from "../../assets/boderline.png"
import imgAreg from "../../assets/before_after_A.jpg";
import imgA350 from "../../assets/before_after_A350.jpg";
import imgBreg from "../../assets/before_after_B.jpg";
import imgB350 from "../../assets/before_after_B350.jpg";
import imgDreg from "../../assets/before_after_D.png";
import imgD350 from "../../assets/before_after_D350.png";

function Success() {

  const imgA = window.innerWidth < 400 ? imgA350 : imgAreg;
  const imgB = window.innerWidth < 400 ? imgB350 : imgBreg;
  const imgD = window.innerWidth < 400 ? imgD350 : imgDreg;

  return (
    <>
      <Header />
      <div className={styles.successStories}>
        <h1>Success Stories</h1>
        <img className={styles.borderline} src={boderline} alt='' />
        <div className={styles.successStory}>
          <div className={styles.storyContainer}>
            <div className={styles.beforeAfter}>
              <img className={styles.beforeAfterImg} src={imgA} alt="Andrea R." />
            </div>
            <h3>Andrea R.</h3>
            <p>
              After having my first daughter, I developed a desire and a need to be in the best shape
              of my adult life. I was prepping meals and hitting the gym nearly six days a week. I
              never felt so great. As I was approaching my fitness goals, I found out I was pregnant
              with my second daughter and, sadly, became depressed. All of my hard work and goals
              would be wiped away. That's ok… I did it once; I could do it again, or so I thought.
            </p>
            <p>
              Unfortunately, it wasn't so simple the second time around. With that second pregnancy,
              my body became baby-accommodating much more quickly, and the changes were more
              significant. I did everything I had done after the first and saw nowhere near the
              results I had achieved before. Over the holidays, I had a complete breakdown. I was so
              unhappy and felt completely defeated. I hated what I saw in the mirror, and I hated it
              even more that my physical being was taking away the joys of all the things I had to
              be thankful for.
            </p>
            <p>
              It wasn't until I got in touch with TB2 that my life would change for the better. With
              just one injection of Tirzepatide a week, I have been able to achieve results I wasn't
              even able to with diet and fitness alone. I fit in clothes I want to fit in, my body
              doesn't ache like it used to, and I find that I am not totally consumed with my
              physical appearance. Best of all, I am able to enjoy other aspects of my life. I don't
              feel “held back” anymore.
            </p>
          </div>
        </div>

        <div className={styles.successStory}>
          <div className={styles.storyContainer}>
              <div className={styles.beforeAfter}>
                <img className={styles.beforeAfterImg} src={imgB} alt="Paul M." />
              </div>
              <h3>Paul M.</h3>
              <p>
                My daughter was 3; I bent over to tie my shoe and let out an involuntary wheeze…
                that was it; something had to change. So I worked out, keto'd, paleo'd, weight
                watcher'd, etc… you name it, I did it. I got in great shape… but didn't lose the
                weight. Toni suggested GLP-1s. I started losing instantaneously. And it was easy.
              </p>
              <p>
                Now, I'm not afraid of mirrors, not scared I'm being judged when I order at a
                restaurant, not fearful that I'm going to overflow into my neighbor's seat while
                on an airplane, and, most importantly, that I will be around a lot longer for my
                daughter. The only downside is my daughter was mad she lost her 'pillow' formerly
                known as my stomach!
              </p>
            
          </div>
        </div>
        <div className={styles.successStory}>
          <div className={styles.storyContainer}>            
              <div className={styles.beforeAfter}>
                <img className={styles.beforeAfterImg} src={imgD} alt="Zishan A." />
              </div>
              <h3>Zishan A.</h3>
              <p>
                Going from being a collegiate soccer player and jiu-jitsu competitor to a family and
                business oriented man: My activity levels went down, but my appetite did not.
              </p>
              <p>
                After a few short years with the same amount of food, increased stress and less activity
                - I found myself overweight, out of shape and embarrassed to walk past the mirror
                without a shirt on.
              </p>
              <p>
                While restrictive fad diets got me close to the results I wanted temporarily, nothing
                stuck or worked very well for both my body and my mental health. Until I found TB2 and
                spoke to my clinician.
              </p>
              <p>
                In 12 months, I have lost 70 LBs, find myself actually wanting to move more and have
                seen my mental health improve as well.
              </p>
              <p>
                I am forever grateful to TB2 and their wonderful clinicians.
              </p>
            
          </div>
        </div>
        <div className={styles.successStory}>
          <div className={styles.storyContainer}>            
              <h3>Mark P.</h3>
              <p>
                I started the weight loss program in mid October of last year and I’m now pleased 
                to have reached a loss of 50 pounds. My total goal was 70 pounds over seven months 
                or roughly 10 pounds a month and I’m pretty happy with the results and feel amazing 
                both personally and emotionally.
              </p>
              <p>
                I decided to do this over this timeframe by eating great food and managing the 
                weight drop not too fast. I implemented three rules:
                1. Not eat anything after 8 o'clock. 2. Cut out all sugar and 3. Drink 3 L of water per day.
              </p>
              <p>  
                I am now also starting to walk 2 to 3 miles a day and light lifting at the gym. 
                <b>This is life-changing.</b>
              </p>            
          </div>
        </div>
        <div className={styles.successStory}>
          <div className={styles.storyContainer}>
            <h3>Jim K.</h3>
            <p>
              I am a 43-year-old high school football coach with three children, work at a
              local college and volunteer any free time I have to the community and local
              church. I was able to help others but, in the process my health was sacrificed.
              The GLP medications enabled me to start my weight loss process, control my
              hunger and influence changes in diet and activity. I was able to lose 33
              pounds in 5 months and have more energy to be an even better father, coach
              and member of our community.
            </p>
          </div>
        </div>
        <div className={styles.successStory}>
          <div className={styles.storyContainer}>
            <h3>Allison H.</h3>
            <p>
              I am a 35-year-old female with rheumatoid arthritis. Joint pain and
              inflammation made it very difficult to partake in physical activity and even
              play with my children. The GLP-1 medications helped me lose 36 pounds in 4
              months. What is equally as liberating as losing weight, is I was able to stop
              taking my autoimmune medication because of the reduction in inflammation. I
              can finally participate in physical activity with my children.
            </p>
          </div>
        </div>
        <div className={styles.successStory}>
          <div className={styles.storyContainer}>
            <h3>Jennifer M.</h3>
            <p>
              I am a 52-year-old female dispatcher. I have been helping people through
              emergencies for the last 13 years working, working 12+ hours shifts. Due to
              little sleep and lots of stress it became very difficult for me to lose
              weight. I tried diet and exercise and lost 10 pounds but plateaued after a
              couple months. I had a friend that used GLP medications, and I was skeptical
              but was desperate to continue losing weight. I was able to lose an additional
              45 pounds over 5 months, have less mental fatigue and have a balanced weight
              in a job that does not involve much physical activity or movement.
            </p>
          </div>
        </div>
        <div className={styles.successStory}>
          <div className={styles.storyContainer}>
            <h3>Kayleigh S.</h3>
            <p>
              I am a 28-year-old nurse with polycystic ovarian syndrome (PCOS) who was
              having trouble getting pregnant. My husband and I had tried for over a year
              and were considering meeting with a fertility specialist and even going as
              far as in vitro if necessary. I had tried Metformin to no avail. My OBGYN
              sat me down and encouraged me to consider losing weight first and then
              trying to conceive. I had tried the fad diets and subscription food programs.
              My OBGYN suggested a GLP medication and said she had success with other PCOS
              patients. I used semaglutide to lose 29 pounds in 3 months. I gradually
              reduced the dose of the medication until I completely stopped and waited
              several months before resuming trying to get pregnant. We were able to
              conceive within three months and now have a beautiful baby girl.
            </p>
          </div>
        </div>
        <div className={styles.successStory}>
          <div className={styles.storyContainer}>
            <h3>Jason P.</h3>
            <p>
              I was not excessively overweight, just on the cusp of obesity, with a belly slowly
              growing over the decades like a beach ball. Being a stomach sleeper, I was waking up
              with back and shoulder pain multiple times a night, making a good night's rest a
              rarity, and I was really starting to feel old and tired all the time. When I hit 50,
              I learned about GLP-1s and decided to give it a try.
            </p>
            <p>
              Honestly my biggest fear was that I would lose my enjoyment of food. I love to cook and
              eat all kinds of cuisine. When I started the medication, I was pleasantly surprised to
              find that I could still enjoy food the same way, but I was satisfied with much smaller
              portions. I could still have cookies, but I only wanted one or two, not the whole box.
              I could still have a burger, but I was satisfied with a single instead of a double-bacon.
            </p>
            <p>
              So far I have lost 16 pounds in 2 months. I still have another 10 pounds to go, but I am
              already sleeping better and feeling better, good enough to start exercising again (not to
              mention I'm spending a lot less money on food). I'm really looking forward to getting
              back to my college physique. Thank you Toni!
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Success;