// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Intake_intake__wZAp5 {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
    font-family: 'Open Sans';
    color: #0d5644;
    height: 100vh;
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Intake/Intake.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,mBAAmB;IACnB,sBAAsB;IACtB,uBAAuB;IACvB,wBAAwB;IACxB,cAAc;IACd,aAAa;IACb,WAAW;AACf","sourcesContent":[".intake {\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    text-align: center;\n    align-items: center;\n    vertical-align: middle;\n    justify-content: center;\n    font-family: 'Open Sans';\n    color: #0d5644;\n    height: 100vh;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"intake": `Intake_intake__wZAp5`
};
export default ___CSS_LOADER_EXPORT___;
