import styles from './Intake.module.css';
import { useEffect } from 'react';

const Intake = () => {

    useEffect(() => {

        const getRedirectUrl = async (eventId) => {

            const tb2ApiUrl = process.env.REACT_APP_TB2_API_URL;            
            await fetch(tb2ApiUrl + '/events/intake', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    eventId: eventId
                })
            })
            .then(response => response.json())
            .then(data => {
                console.log(data); 
                if (!data.redirectUrl) { 
                    console.error('Error getting redirect url:', data);
                }
                else {
                    localStorage.setItem('redirect_url', data.redirectUrl);
                    window.location.href = data.redirectUrl;
                }
            })
            .catch(error => {
                console.error('Error in intake:', error);
            });
        }

        const redirectUrl = localStorage.getItem('redirect_url');
        const eventId = new URLSearchParams(window.location.search).get('eid');

        if (redirectUrl) {
            window.location.href = redirectUrl;
        }
        else if (eventId) {
            getRedirectUrl(eventId);
        }
        else {
            console.error('No event ID found in URL or redirectUrl in local storage.');
        }
        
    }, []);
    
    return (
        <div className={styles.intake}>
            <h3>Congratulations!</h3>
            <p>Your payment was successful.</p>
            <p>Redirecting to your account...</p>
        </div>
    )
}

export default Intake;