import React, { useEffect, useRef, useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { handleRedirect } from '../../utils/redirectUtils';

import styles from './WomanHero.module.css';

import circleright from "../../assets/circle-right.svg";

function WomanHero() {

    const [isVisible, setIsVisible] = useState(false);
    const elementRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            {
                threshold: 0.5,
            }
        );

        let currentElement = elementRef.current;
        if (currentElement) {
            observer.observe(currentElement);
        }
        return () => {
            if (currentElement) {
                observer.unobserve(currentElement);
            }
        };
    }, []);

    return (
        <>
            <div  className={styles.heroWoman}>
                <section className={styles.hero}>
                    <div className={styles.heroImage}><img src="images/hero-woman.png" alt="" /></div>
                    <div ref={elementRef}  className={styles.heroText}>
                        <div className={isVisible ? 'animate__animated animate__fadeInUp' : ''}><span  className={styles.heroGradient}>Finally</span></div>
                        <div className={styles.heroSafe + (isVisible ? ' animate__animated animate__fadeInUp' : '')}>Safe, Effective, Proven</div>
                        <div className={styles.heroWeight + (isVisible ? ' animate__animated animate__fadeInUp' : '')} >Weight Loss<span className={styles.heroAsterisk}>*</span></div>
                        <div className={styles.heroButton} onClick={handleRedirect}>Let's Do This! <img className={styles.buttonArrow} src={circleright} alt=''/></div>
                        <div className={styles.bullets}>
                            <div>
                                <span className={styles.bullet}>•</span> No Insurance Needed<br />
                                <span className={styles.bullet}>•</span> Same-Day Prescriptions
                            </div>
                            <div>
                                <span className={styles.bullet}>•</span> Overnight Shipping<br />
                                <span className={styles.bullet}>•</span> No Hidden Fees
                            </div>
                        </div>
                        <div className={styles.heroAsterisk}><Link to="/knowledge-center">*Semaglutide</Link> / <Link to="/knowledge-center">Tirzepatide**</Link></div>
                        <div className={styles.heroSubtext}>**Semaglutide and Tirzepatide are GLP-1 agonists approved by the FDA for weight loss. Semaglutide is the active ingredient in Ozempic<sup>&#174;</sup> and Wegovy<sup>&#174;</sup>. Tirzepatide is the active ingredient in Mounjaro<sup>&#174;</sup> and Zepbound<sup>&#174;</sup>.</div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default WomanHero;
