import React, { useState, useEffect } from 'react';
import styles from './Popup.module.css';

const Popup = ({ onClose }) => {
    const [email, setEmail] = useState('');
    const [question, setQuestion] = useState('');
    const [showPopup, setShowPopup] = useState(true);

    useEffect(() => {
        const storage = localStorage.getItem("responseSuccessDate");
        const storage2 = localStorage.getItem("todaysDate");
        if (storage !== null) {
            const storageDate = new Date(storage);
            const storage2Date = new Date(storage2);
            const differenceInMilliseconds = storageDate - storage2Date;
            const differenceInDays = differenceInMilliseconds / (1000 * 3600 * 24);


            if (differenceInDays > 3) {
                setShowPopup(true);
            } else {
                setShowPopup(false);
                onClose();
            }
        }
    }, [onClose]);

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };
    const handleQuestionChange = (e) => {
        setQuestion(e.target.value);
    };
    const handleSubmit = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            alert('Invalid email format');
            return;
        } else {
            const data = {
                email: email,
                question: question,
                source: window.location.origin
            };

            fetch(process.env.REACT_APP_TB2_API_URL + '/events/lead', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then(response => {
                    if (response.ok) {
                        console.log('Response=====>>>>>>Tb2', response);
                        onClose();
                        const currentDate = new Date().toISOString().slice(0, 10);
                        localStorage.setItem('responseSuccessDate', currentDate);
                    } else {
                        console.error('Failed to add lead:', response.statusText);
                    }
                })
                .catch(error => {
                    console.error('Error adding lead:', error);
                });
        }
    }

    return (
        <>
            {showPopup && (
                <div className={styles.popup}>
                    <div className={styles.container}>
                        <h4>Enter your email address to get in touch with a patient care coordinator who can answer your questions</h4>
                        <input
                            type="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={handleEmailChange}
                            className={styles.emailField}
                        />
                        <textarea
                            placeholder="(optional) Enter your questions. Do not include personal health information."
                            value={question}
                            onChange={handleQuestionChange}
                            className={styles.questionField}
                        />
                        <div className={styles.disclaimer}>
                            By submitting your email, you agree to receive email communication from TB2. <br/>See our <a href='/privacy' target='_blank'>privacy policy</a>.
                        </div>
                        <div className={styles.buttonContainer}>
                            <button className={styles.closeButton}  onClick={onClose}>Close</button>
                            <button className={styles.submitButton} onClick={handleSubmit}>Submit</button>
                        </div>
                    </div>
                </div>
             )}
        </>
    );
};

export default Popup;
