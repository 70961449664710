import React, { useEffect, useRef, useState } from 'react';

import styles from './Support.module.css';
import circleright from "../../assets/circle-right.svg";
import supportimg from "../../assets/support-img.png";
import AuthorityMagazine from "../../assets/Authority-Magazine.png";
import AuthorityMagazine350 from "../../assets/Authority-Magazine350.png";
import MedicalNewsToday from "../../assets/MedicalNewsToday.png";
import MedicalNewsToday350 from "../../assets/MedicalNewsToday350.png";
import DailyMail from '../../assets/daily-mail.png';
import DailyMail350 from '../../assets/daily-mail350.png';


function Support() {
    const [isVisible, setIsVisible] = useState(false);
    const elementRef = useRef(null);
    const articlesRef = useRef(null);
    const meetRef = useRef(null);

    const authorityMagazineImg = window.innerWidth < 400 ? AuthorityMagazine350 : AuthorityMagazine;
    const medicalNewsTodayImg = window.innerWidth < 400 ? MedicalNewsToday350 : MedicalNewsToday;
    const dailyMailImg = window.innerWidth < 400 ? DailyMail350 : DailyMail;

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            {
                threshold: 0.5,
            }
        );

        let currentElement = elementRef.current;
        if (currentElement) {
            observer.observe(currentElement);
        }
        return () => {
            if (currentElement) {
                observer.unobserve(currentElement);
            }
        };
    }, []);
    
    function handleGetStarted() {
        window.location.href = '/start';
    }

    function handleMeet() {
        window.location.href = '/about/';
    }
    function handleArticles() {
        window.location.href = '/articles/';
    }
    function handleArticlesHover(e) {
        e.target.classList.add('animate__animated', 'animate__pulse', styles.glowGreen);
    }
    function handleArticlesHoverEnd(e) {
        e.target.classList.remove('animate__animated', 'animate__pulse', styles.glowGreen);
    }
    function handleMeetHover(e) {
        e.target.classList.add('animate__animated', 'animate__pulse', styles.glowBlue);
    }
    function handleMeetHoverEnd(e) {
        e.target.classList.remove('animate__animated', 'animate__pulse', styles.glowBlue);
    }


    return (
        <>
            <div>
                <div>
                    <div ref={elementRef} className={styles.featured}>
                        <h4 className={styles.featuredTitle}>Featured In:</h4>
                        <div className={styles.teamBrands}>
                            <img className={styles.brandImg} src={dailyMailImg} alt='Daily Mail' onClick={() => {window.open('https://www.dailymail.co.uk/health/article-13276757/deaths-linked-ozempic-weight-loss-drugs-analysis.html')}} />
                            <img className={styles.brandImg} src={authorityMagazineImg} alt='Authority Magazine' onClick={() => {window.open('https://medium.com/authority-magazine/paul-moss-and-antoni-adamrovich-of-tb2-health-49f6d473922c')}} />
                            <img className={styles.brandImg} src={medicalNewsTodayImg} alt='Medical News Today' onClick={() => {window.open('https://www.medicalnewstoday.com/articles/how-semaglutide-drugs-such-as-ozempic-can-help-with-long-term-weight-loss-and-heart-health')}} />
                        </div>
                        <p ref={articlesRef} className={styles.journeyButton + (isVisible ? ' animate__animated animate__rubberBand' : '')} onMouseEnter={handleArticlesHover} onMouseLeave={handleArticlesHoverEnd} onClick={handleArticles}>See All Features <img className={styles.buttonArrow} src={circleright} alt=''/></p>
                    </div>
                </div>
                <div className={styles.bg3}>
                    <div ref={elementRef} className={styles.supportPanel} >
                        <div className={styles.supportText + (isVisible ? ' animate__animated animate__fadeInUp' : '')}>Support <span>(Success)</span><br />At Your Fingertips</div>
                        <div className={styles.supportSubtext}>Prescription medication formulated specifically for YOU and your goals. <span> Clinician approved nutrition, workout plans, and checkin-ins for</span> SUCCESS! </div>
                    </div>
                    <div className={styles.supportPanel + ' ' + styles.imagePanel}>
                        <img className={styles.supportImg} src={supportimg} alt='' />
                    </div>
                </div>
                <div className={styles.meetOurTeam}>
                    <div className={styles.meetButtonBox}>
                        <div ref={meetRef} className={styles.meetButton} onMouseEnter={handleMeetHover} onMouseLeave={handleMeetHoverEnd} onClick={handleMeet}>Meet Our Team<img className={styles.buttonArrow} src={circleright} alt='' /> </div>
                    </div>
                    <div className={styles.blueBar}></div>
                </div>
            </div>
        </>
    );
}

export default Support;
