import React, { useMemo } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styles from './SuccessStories.module.css';

function SuccessStories() {

    const testimonials = useMemo(() => [
        {
          text: "I am so appreciative of Toni and  his encouragement! The efficacy of the medication and ease of service provided as well as the competitive pricing keeps me returning.",
          signature: "Cynthia H."
        },
        {
          text: "I am so thankful for Toni and all his help and guidance! My only regret is I wish I would have started sooner! He is always prompt to respond to questions and concerns.  Highly recommend him!",
          signature: "Taryn K."
        },
        {
          text: "Great company. Great clinicians. Great people. I highly recommend them!",
          signature: "Sana F."
        },
        {
          text: "I have been over weight most of my life and made a decision to make a life style change. I have been on the tb2 weight loss program for 90 days and have lost 33 pounds so far. I have had no side effects and couldn't be happier with my results. My goal is to get to 70 pounds by June of 2024. Already my knees and hip joints feel so much better walking and I have given away multiple bags of clothes that no lomger fit. I am going to start a light weight lifting program to tone my muscles. I highly recommend this program.",
          signature: "Mark P."
        },
        {
          text: "I was started on weight loss injections after struggling to lose weight due to hypothyroidism and endometriosis. I was able to lose 25 pounds in 10 weeks. On top of that, it helped my endometriosis so tremendously that I was able to cancel my third surgical removal procedure. It has definitely been life changing for me!",
          signature: "Leslie S."
        },
        {
          text: "The clinicians are very informative and quick to assist in all your needs Highly recommend!",
          signature: "Tyler C."
        },
        {
          text: "Extremely polite, knowledgeable, and friendly staff, would highly recommend for anyone of all ages",
          signature: "Kaz V."
        },
        {
          text: "I have struggled with weight loss most of my life. Endometriosis, hypothyroidism, menopause (and a food obsession)...it was a constant yoyo battle. Enter tirzepatide and Toni!!  For the first time ever...the 'food noise' is gone. I'm down almost 30# and feeling better in my 50s than I did in my 40s. Combine this with Toni's easy relatable approach for a total win...so grateful him and this product!!",
          signature: "jen s."
        },
        {
          text: "Very effect. Everything was fast and easy! Couldn`t be happier with the results!",
          signature: "Lauren U."
        },
        {
            text: "Tirzepatide, Toni (the Nurse Practitioner), & TB2 probably saved my life. 70lbs in 9 months- weight's still off 9 months later. Thank you!",
            signature: "Paul M."
        },
        {
            text: "I can't believe how well tirzepatide is working for me. I'm down almost 20lbs in less than 3 months. I'm sleeping better and actually feel like exercising again. Thank you TB2!",
            signature: "Jason P."
        },
        {
          text: 'This product is AMAZING 🤩 My appetite has reduced tremendously and I don’t crave the things I used to. The Results speak for themselves',
          signature: 'Russell W.'
        }
      ], []);
    
      const TestimonialsCarousel = () => {
        const handleOnChange = (index) => {
          // console.log("Selected item index: " + index);
        }
    
        return (
          <Carousel
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
            showArrows={window.innerWidth > 350 ? true : false}
            dynamicHeight={true}
            autoPlay
            infiniteLoop
            interval={5000}
            onChange={handleOnChange}
          >
            {testimonials.map((testimonial, index) => (
              <div key={index} className={styles.testimonials}>
                <div>
                  <svg xmlns='http://www.w3.org/2000/svg' height='17' viewBox='0 0 23.44 19'><polygon fill='#fdd663' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>
                  <svg xmlns='http://www.w3.org/2000/svg' height='17' viewBox='0 0 23.44 19'><polygon fill='#fdd663' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>
                  <svg xmlns='http://www.w3.org/2000/svg' height='17' viewBox='0 0 23.44 19'><polygon fill='#fdd663' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>
                  <svg xmlns='http://www.w3.org/2000/svg' height='17' viewBox='0 0 23.44 19'><polygon fill='#fdd663' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>
                  <svg xmlns='http://www.w3.org/2000/svg' height='17' viewBox='0 0 23.44 19'><polygon fill='#fdd663' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>
                </div>
                <p>{testimonial.text}</p>
                <p className={styles.signature}>{testimonial.signature}</p>
              </div>
            ))}
          </Carousel>
        );
      };

    return (
        <>
            <div className={styles.bg5}>
                <div className={styles.successBox}>
                    <div className={styles.success}>
                        <h2>Success Stories</h2>
                    </div>
                    <TestimonialsCarousel />
                </div>
            </div>
        </>
    );
}

export default SuccessStories;
