// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Privacy_privacy__ua\\+GE {
    font-family: 'Aktiv Grotesk',sans-serif;
    color: #0A2945;
    margin: 0 auto;
    padding: 5px;
    margin-top: 90px;
    margin-bottom: 20px;
    width: 70%;
}
.Privacy_privacy__ua\\+GE h1 {
    text-align: center;
    font-size: 2.5rem;
    font-weight: 700;
}
.Privacy_privacy__ua\\+GE li {
    margin-bottom: 10px;
}
.Privacy_privacy__ua\\+GE P {
    font-size: 18px;
    font-weight: 400;
}
.Privacy_privacy__ua\\+GE h2 {
    font-weight: 700;
    font-size: 24px;
}
.Privacy_paragraph__PARJ8 {
    margin: 15px 0;
}
@media screen and (max-width: 768px) {
    .Privacy_privacy__ua\\+GE {
    width: 90% !important;
    }
    .Privacy_privacy__ua\\+GE h1{
        font-size: 1.75rem !important;
    }
    .Privacy_privacy__ua\\+GE p{
        font-size: 14px !important;
    }
    .Privacy_privacy__ua\\+GE h2{
        font-size: 1.25rem !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Privacy/Privacy.module.css"],"names":[],"mappings":"AAAA;IACI,uCAAuC;IACvC,cAAc;IACd,cAAc;IACd,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;IACnB,UAAU;AACd;AACA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,gBAAgB;AACpB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,cAAc;AAClB;AACA;IACI;IACA,qBAAqB;IACrB;IACA;QACI,6BAA6B;IACjC;IACA;QACI,0BAA0B;IAC9B;IACA;QACI,6BAA6B;IACjC;AACJ","sourcesContent":[".privacy {\n    font-family: 'Aktiv Grotesk',sans-serif;\n    color: #0A2945;\n    margin: 0 auto;\n    padding: 5px;\n    margin-top: 90px;\n    margin-bottom: 20px;\n    width: 70%;\n}\n.privacy h1 {\n    text-align: center;\n    font-size: 2.5rem;\n    font-weight: 700;\n}\n.privacy li {\n    margin-bottom: 10px;\n}\n.privacy P {\n    font-size: 18px;\n    font-weight: 400;\n}\n.privacy h2 {\n    font-weight: 700;\n    font-size: 24px;\n}\n.paragraph {\n    margin: 15px 0;\n}\n@media screen and (max-width: 768px) {\n    .privacy {\n    width: 90% !important;\n    }\n    .privacy h1{\n        font-size: 1.75rem !important;\n    }\n    .privacy p{\n        font-size: 14px !important;\n    }\n    .privacy h2{\n        font-size: 1.25rem !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"privacy": `Privacy_privacy__ua+GE`,
	"paragraph": `Privacy_paragraph__PARJ8`
};
export default ___CSS_LOADER_EXPORT___;
