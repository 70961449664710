import { Link } from 'react-router-dom';
import Header from '../.shared/header';
import Footer from '../.shared/footer';
import boderline from '../../assets/boderline.png';
import styles from './Blog.module.css';

function Blog() {
    return (
        <>
        <Header />
        <div><h1 className={styles.BlogTitle}>Blog</h1></div>
        <img className={styles.BlogBorder} src={boderline} alt='' />
        <div className={styles.Blog}>
            <div className={styles.FeaturedArticle}>
                <div className={styles.FeaturedCover}><Link to='/articles/TheImpactOfObesityOnMentalHealth'><img src='images/TheImpactOfObesityOnMentalHealth.jpg' alt='The Impact of Obesity On Mental Health' /></Link></div>
                <div className={styles.FeaturedByline}>Author: Toni Adamrovich, MSN, BA, APRN, FNP-C - Chief of Medicine @ TB2 - 02/19/24</div>
                <div className={styles.FeaturedDesc}>A patient once said that if men think about sex every 7 seconds, I think about 
                my weight every 3 seconds. <Link to='/articles/TheImpactOfObesityOnMentalHealth'>Read more...</Link>
                </div>
            </div>
            <div className={styles.FeaturedArticle}>
                <div className={styles.FeaturedCover}><Link to='/articles/MitigatingSideEffectsOfGLP1'><img src='images/SideEffectsAndMitigation.jpg' alt='Mitigating Side Effects of GLP-1 Medications' /></Link></div>
                <div className={styles.FeaturedByline}>Author: Toni Adamrovich, MSN, BA, APRN, FNP-C - Chief of Medicine @ TB2 - 01/15/24</div>
                <div className={styles.FeaturedDesc}>Glucagon-like peptide-1 (GLP-1) receptor agonists have emerged as 
                a valuable therapeutic option for managing obesity and type 2 
                diabetes, demonstrating efficacy in weight loss and glycemic 
                control. However, like many medications, they can occasionally 
                elicit side effects in some individuals. Fortunately, several 
                strategies can help reduce and manage these side effects <Link to='/articles/MitigatingSideEffectsOfGLP1'>Read more...</Link>
                </div>
            </div>
            <div className={styles.FeaturedArticle}>
                <div className={styles.FeaturedCover}><Link to='/articles/ExploringThePositiveEffectsOfGLP1'><img src='images/ExploringThePositiveEffectsOfGLP1.jpg' alt='Exploring the Positive Effects of GLP-1 Medications' /></Link></div>
                <div className={styles.FeaturedByline}>Author: Toni Adamrovich, MSN, BA, APRN, FNP-C - Chief of Medicine @ TB2 - 12/18/23</div>
                <div className={styles.FeaturedDesc}>Glucagon-like peptide-1 (GLP-1) receptor agonists have emerged as 
                    a revolutionary class of medications, offering a spectrum of 
                    benefits beyond their initial role in managing type 2 diabetes. 
                    These medications, known for their efficacy in improving glycemic 
                    control, have showcased a range of positive effects that extend 
                    far beyond glucose regulation. <Link to='/articles/ExploringThePositiveEffectsOfGLP1'>Read more...</Link>
                </div>
            </div>
            <div className={styles.FeaturedArticle}>
                <div className={styles.FeaturedCover}><Link to='/articles/TheTB2SemaglutideChallenge'><img src='images/TheTB2SemaglutideChallenge.jpg' alt='The TB2 Semaglutide Challenge: 30 Days to a New You!' /></Link></div>
                <div className={styles.FeaturedByline}>Author: Toni Adamrovich, MSN, BA, APRN, FNP-C - Chief of Medicine @ TB2 - 11/20/23</div>
                <div className={styles.FeaturedDesc}>Embarking on a weight loss journey is a personal quest, 
                but what if there was a transformative challenge that could kickstart your path to a 
                new you? <Link to='/articles/TheTB2SemaglutideChallenge'>Read more...</Link>
                </div>
            </div>
            <div className={styles.FeaturedArticle}>
                <div className={styles.FeaturedCover}><Link to='/articles/UnlockingThePowerOfGLPs'><img src='images/UnlockingThePowerOfGLPs.jpg' alt='Unlocking the Power of GLPs: Your Guide to Semaglutide vs. Tirzepatide' /></Link></div>
                <div className={styles.FeaturedByline}>Author: Toni Adamrovich, MSN, BA, APRN, FNP-C - Chief of Medicine @ TB2 - 10/16/23</div>
                <div className={styles.FeaturedDesc}>In the realm of weight loss medications, GLP-1 receptor agonists have 
                emerged as powerful tools in helping individuals achieve and maintain a healthier weight. This blog 
                post will delve into two notable options, Semaglutide and Tirzepatide, exploring their mechanisms, 
                benefits, and potential differences to help you make an informed decision on your weight loss 
                journey. <Link to='/articles/UnlockingThePowerOfGLPs'>Read more...</Link>
                </div>
            </div>
        </div>
        <Footer />        
        </>        
    );
}

export default Blog;